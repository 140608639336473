export const webosUserAgents = {
  '24': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.5359.211 Safari/537.36 WebAppManager',
    chromeVersion: 108,
  },
  '23': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.128 Safari/537.36 WebAppManager',
    chromeVersion: 94,
  },
  '22': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36 WebAppManager',
    chromeVersion: 87,
  },
  '6.x': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.79 Safari/537.36 WebAppManager',
    chromeVersion: 79,
  },
  '5.x': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/68.0.3440.106 Safari/537.36 WebAppManager',
    chromeVersion: 68,
  },
  '4.x': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/53.0.2785.34 Safari/537.36 WebAppManager',
    chromeVersion: 53,
  },
  '3.x': {
    value:
      'Mozilla/5.0 (Web0S; Linux/SmartTV) AppleWebKit/537.36 (KHTML, like Gecko) QtWebEngine/5.2.1 Chrome/38.0.2125.122 Safari/537.36 WebAppManager',
    chromeVersion: 38,
  },
};

export function getWebOSVersionByChromeVersion(requestChromeVersion: number) {
  let version = 'WebOS Unknown';

  const agents = Object.entries(webosUserAgents);

  for (const [key, value] of agents) {
    const { chromeVersion } = value;

    if (chromeVersion === requestChromeVersion) {
      version = key;
      break;
    }
  }

  return version;
}
