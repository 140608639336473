<template>
  <div :class="$style.preview">
    <!-- Добавить сюда картинки, когда бек начнет их присылать -->
    <timeline-frame-preview-image v-if="false" src="" :on-click="() => {}" />
    <timeline-display-label
      v-if="!isRewindShown && !isRewindTipNotificationShown"
      :class="$style.label"
      :display-time="previewDisplayTime"
      :left-position="safeMouseXFraction"
    />
  </div>
</template>

<script lang="ts" setup>
import { getNormalizedCurrentShownTime, getTimeInSeconds } from '@PLAYER/player/base/date';
import TimelineDisplayLabel from '@PLAYER/player/components/timeline/TimelineDisplayLabel.vue';
import TimelineFramePreviewImage from '@PLAYER/player/components/timeline/TimelineFramePreviewImage.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoControlsVariables from '@PLAYER/player/modules/video/use-video-controls-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps<{
  timelineMouseX: number;
  timelineLeft: number;
  timelineWidth: number;
  pixelFactor: number;
  duration: number;
  isLive: boolean;
  isLiveWithDvr: boolean;
}>();

const { isSmartTV } = usePlatform();
const { isRewindShown } = useVideoControlsVariables();
const { isRewindTipNotificationShown } = storeToRefs(useVideoControlsStore());

const mouseXFraction = computed(() => props.timelineMouseX - props.timelineLeft);

const safeMouseXFraction = computed(() => {
  if (isSmartTV) {
    if (props.timelineMouseX <= props.timelineLeft) {
      return 0;
    }

    if (props.timelineMouseX + 25 >= props.timelineWidth - 5) {
      return props.timelineWidth - 50;
    }
  }

  return mouseXFraction.value;
});

defineExpose({
  safeMouseXFraction,
});

const previewDisplayTime = computed(() => {
  if (props.isLiveWithDvr) {
    const currentTimeInSeconds = getTimeInSeconds(new Date());
    const timeshiftDeltaInSeconds = props.duration - mouseXFraction.value * props.pixelFactor;

    return getNormalizedCurrentShownTime(currentTimeInSeconds - timeshiftDeltaInSeconds);
  }

  if (props.isLive) {
    return mouseXFraction.value * props.pixelFactor - props.duration;
  }

  return mouseXFraction.value * props.pixelFactor;
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

.preview {
  transform: translateY(0px);
  will-change: left;
}

.label {
  bottom: var(--g-spacing-10);
  transform: translateX(-50%);
}
</style>
