<template>
  <root-video-z-index-layer :class="$style.gradients" fullscreen>
    <div
      v-show="isShownGradients || isTopGradientShown"
      :class="{
        [$style.backgroundTop]: true,
        [$style.webBackgroundTop]: isWeb,
        [$style.smartTvBackgroundTop]: isSmartTV,
      }"
    />
    <div
      v-show="isShownGradients"
      :class="{
        [$style.backgroundBottom]: true,
        [$style.webBackgroundBottom]: isWeb,
        [$style.smartTvBackgroundBottom]: isSmartTV,
      }"
    />
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

defineProps<{
  isTopGradientShown?: boolean;
}>();

const { isShownPopup, isNextContentEpisodeShown } = storeToRefs(useLayoutStore());
const { isWeb, isSmartTV } = usePlatform();
const { isControlsVisible } = storeToRefs(useVideoControlsStore());

const isShownGradients = computed(() => {
  if (isSmartTV) {
    return false;
  }

  return isControlsVisible.value || isShownPopup.value || isNextContentEpisodeShown.value;
});
</script>

<style lang="scss" module>
.gradients {
  z-index: var(--z-index-smart-tv-backgrounds);

  --smart-tv-background-gradient-height: 200px;
  --smart-tv-background-gradient-bottom-height: 400px;
  --smart-tv-background-gradient-top: linear-gradient(
    179.25deg,
    rgba(9, 30, 30, 0.76) 0.64%,
    rgba(9, 30, 30, 0) 95.24%
  );

  --smart-tv-background-gradient-bottom: linear-gradient(
    359.7deg,
    rgba(9, 30, 30, 1) 0.64%,
    rgba(9, 30, 30, 0.65) 35.5%,
    rgba(9, 30, 30, 0) 92.45%
  );

  --web-background-gradient-top-height: 286px;
  --web-background-gradient-bottom-height: 132px;
  --web-background-gradient-top: linear-gradient(180deg, rgba(10, 30, 31, 0.6) -5.36%, rgba(10, 30, 31, 0) 91.43%);
  --web-background-gradient-bottom: linear-gradient(0deg, rgba(10, 30, 31, 0.6) 0%, rgba(10, 30, 31, 0) 100%);
}

.backgroundTop {
  position: absolute;
  top: 0;
  width: 100%;
}

.webBackgroundTop {
  height: var(--web-background-gradient-top-height);
  background: var(--web-background-gradient-top);
}

.smartTvBackgroundTop {
  height: var(--smart-tv-background-gradient-height);
  background: var(--smart-tv-background-gradient-top);
}

.backgroundBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.webBackgroundBottom {
  height: var(--web-background-gradient-bottom-height);
  background: var(--web-background-gradient-bottom);
}

.smartTvBackgroundBottom {
  height: var(--smart-tv-background-gradient-bottom-height);
  background: var(--smart-tv-background-gradient-bottom);
}

@container rootVideo (min-width: 858px) {
  .webBackgroundTop {
    height: 172px;
  }

  .webBackgroundBottom {
    height: 80px;
  }
}
</style>
