<template>
  <provide-media-source-context
    :tech-playback-type="mediaSourceTechPlaybackType"
    :video-el="videoEl"
    :auto-start-load="autoStartLoad"
    :on-set-media-source-tech="onSetMediaSourceTech"
  >
    <template v-if="mediaSourceTech">
      <media-source-runtime-manager
        :tech-playback-type="mediaSourceTechPlaybackType"
        :media-source-tech="mediaSourceTech"
        :inject-global-functions="true"
      />
      <media-source-loader-manager />
      <media-source-quality-manager />
      <media-source-subtitle-manager />
      <media-source-manifest-load-detector />
      <media-source-fatal-error-trap :tech-playback-type="mediaSourceTechPlaybackType" />
      <slot />
    </template>
  </provide-media-source-context>
</template>

<script lang="ts" setup>
import type MediaSourceTech from '@package/media-player-tech/src/media-source-tech';
import { isFunction } from '@package/sdk/src/core';
import MediaSourceFatalErrorTrap from '@PLAYER/player/components/app-video-context/MediaSourceFatalErrorTrap.vue';
import MediaSourceLoaderManager from '@PLAYER/player/components/app-video-context/MediaSourceLoaderManager.vue';
import MediaSourceManifestLoadDetector from '@PLAYER/player/components/app-video-context/MediaSourceManifestLoadDetector.vue';
import MediaSourceQualityManager from '@PLAYER/player/components/app-video-context/MediaSourceQualityManager.vue';
import MediaSourceRuntimeManager from '@PLAYER/player/components/app-video-context/MediaSourceRuntimeManager.vue';
import MediaSourceSubtitleManager from '@PLAYER/player/components/app-video-context/MediaSourceSubtitleManager.vue';
import ProvideMediaSourceContext from '@PLAYER/player/components/app-video-context/ProvideMediaSourceContext.vue';
import type { DevicePerformanceEvent, VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import onInitialPlaybackStarted from '@PLAYER/player/modules/hooks/on-initial-playback-started';
import usePerformanceDetector from '@PLAYER/player/modules/hooks/use-performance-detector';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { BadPlayerPerformanceError, PerformanceEvent } from '@PLAYER/player/modules/performance/performance';
import useAnalyticsStore from '@PLAYER/player/modules/store/analytics-store';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useSafeVideoElement from '@PLAYER/player/modules/video/use-safe-video-element';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { markRaw, provide, ref } from 'vue';

defineProps<{ autoStartLoad?: boolean }>();

const mediaSourceTech = ref<MediaSourceTech>();
provide('getMediaSourceTech', mediaSourceTech);

const videoEl = useSafeVideoElement();
const eventBus = useSafeEventBus();
const externalEventBus = useSafeExternalEventBus();
const analyticsStore = useAnalyticsStore();
const { manifestUrl } = storeToRefs(useManifestStore());

const { startTrackingPerformance } = usePerformanceDetector();
const { isKinom } = useProjector();
const { mediaSourceTechPlaybackType } = useVideoPlayerVariables();

const onSetMediaSourceTech = (instance: MediaSourceTech) => {
  markRaw(instance);

  if (!manifestUrl.value) {
    return;
  }

  mediaSourceTech.value = instance;
};

const doStartTrackingPerformance = () => {
  // Трекаем только для плеера фильма/ВТ
  if (isKinom.value) {
    return;
  }

  if (isFunction(window.requestIdleCallback)) {
    window.requestIdleCallback(startTrackingPerformance);
  } else {
    startTrackingPerformance();
  }
};

const onPerformanceTracked = (event: VideoPlayerInternalEvent<DevicePerformanceEvent>) => {
  const { performance, fps } = event.data;

  if (performance === 'weak') {
    const devicePerformanceError = new BadPlayerPerformanceError(PerformanceEvent.DevicePerformance, {
      performance,
      fps,
    });

    externalEventBus.emit('error', devicePerformanceError);
  }

  analyticsStore.setDevicePerformance(performance);
};

eventBus.on('onPerformanceTracked', onPerformanceTracked);

onInitialPlaybackStarted(doStartTrackingPerformance);
</script>
