<template>
  <div
    :class="{
      [$style.bufferProgress]: true,
      [$style.bufferProgressKinom]: isKinom,
    }"
    :style="{ width: $toPercent(currentProgress * 100) }"
  />
</template>

<script lang="ts" setup>
import type { TimeSeconds } from '@PLAYER/player/base/number';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { computed } from 'vue';

const props = defineProps<{
  duration: TimeSeconds;
  bufferLengthSeconds: TimeSeconds;
  bufferStartSeconds: TimeSeconds;
  currentTime: TimeSeconds;
}>();

const { isKinom } = useProjector();

const currentProgress = computed(() => {
  const progress = (props.currentTime + props.bufferLengthSeconds) / props.duration;

  if (progress < 0) {
    return 0;
  }

  if (progress > 1) {
    return 1;
  }

  return progress;
});
</script>

<style lang="scss" module>
.bufferProgress {
  position: absolute;
  z-index: 0;
  width: 0;
  background-color: var(--color-notheme-white-40);
}

.bufferProgressKinom {
  height: 5px;
}
</style>
