<template>
  <NavigatableItem
    :active-class="$style.active"
    :class="{
      [$style.item]: true,
      [$style.selected]: isSelected,
    }"
    :tag="UIButton"
    @active="emit('active')"
    @click="emit('selected')"
  >
    <template #text>
      <div :class="$style.title">
        <div :class="$style.titleText">
          <slot name="icon" />
          <span><slot name="text" /></span>
          <slot name="icon" />
          <span><slot name="text" /></span>
        </div>
        <CheckIcon v-if="isSelected" :class="$style.titleState" />
      </div>
    </template>
  </NavigatableItem>
</template>

<script setup lang="ts">
import CheckIcon from '@SMART/assets/icons/51x51/check.svg';

import UIButton from '@/components/button/UIButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

interface Props {
  isSelected: boolean;
}

defineProps<Props>();

const emit = defineEmits<{
  (e: 'active'): void;
  (e: 'selected'): void;
}>();
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: adjustPx(24px);
  padding: adjustPx(24px);
  width: adjustPx(604px);
  height: adjustPx(96px);
  border-radius: adjustPx(24px);
  background: var(--color-bg-secondary);

  &:hover {
    background: var(--color-notheme-bg-accent);
    color: var(--color-notheme-text-accent);

    img {
      filter: brightness(0%);
    }
  }
}

.selected {
  border-radius: adjustPx(32px);
  background: var(--color-bg-tertiary);
}

.active {
  background: var(--color-notheme-bg-accent);
  color: var(--color-notheme-text-accent);

  img {
    filter: brightness(0%);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &Text {
    display: flex;
    align-items: center;
  }

  &State {
    margin-right: adjustPx(16px);
    width: adjustPx(48px);
    height: adjustPx(48px);
  }
}
</style>
