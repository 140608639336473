<template>
  <template v-if="isLoading">
    <div :class="[$style.shimmer, shimmerClass, $attrs.class as string]" :style="{ width: widthPx }" />
  </template>
  <img
    v-if="!isLoading && (isFallback || !hasError)"
    data-image
    :width="width"
    :src="normalizedSrc"
    :loading="loading"
    :alt="alt"
    :class="{ [$style.fallback]: isFallback, [$attrs.class as string]: true }"
    @error="handleError"
  />
</template>

<script lang="ts" setup>
import useCDNImage from '@package/content-utils/src/code/use-cdn-image';
import IconPhoto from '@SMART/assets/icons/51x51/placeholder.svg?inline';
import { computed, ref, watch } from 'vue';

interface Props {
  src?: string;
  alt?: string;
  width?: number;
  loading?: 'eager' | 'lazy';
  useFallbackIcon?: boolean;
  isLoading?: boolean;
  shimmerClass?: string;
}

const { src, useFallbackIcon = true, width = 200, loading = 'lazy' } = defineProps<Props>();

const { getCDNLink, isCDNLink } = useCDNImage();

const hasError = ref(false);
const handleError = () => {
  hasError.value = true;
};

const isFallback = computed(() => (!src || hasError.value) && useFallbackIcon);
const widthPx = computed(() => width + 'px');

const normalizedSrc = computed(() => {
  if (isFallback.value) {
    return IconPhoto;
  }

  if (isCDNLink(src)) {
    return src;
  }

  return getCDNLink(src, width);
});

watch(
  () => src,
  () => (hasError.value = false),
);
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@package/ui/src/styles/shimmers';

.fallback {
  transform: none !important;
  align-self: center;
  width: adjustPx(44.891px) !important;
  height: adjustPx(44.891px) !important;
  border-radius: 0;
}
</style>
