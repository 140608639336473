<template>
  <slot />
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import useEffect from '@PLAYER/player/modules/global/use-effect';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';

const videoUIStore = useVideoUIStore();
const externalEventBus = useSafeExternalEventBus();
const logger = useLogger('VideoStateDetector', 'media-player');

const { isLive, isVOD } = useProjector();

const onEnterPictureInPicture = () => {
  videoUIStore.setIsPictureInPictureEnabled(true);

  if (isLive.value || isVOD.value) {
    externalEventBus.emit('picture-in-picture', new VideoPlayerExternalEvent({ active: true }));
  }

  logger.info('VideoStateDetector#enterPictureInPicture');
};

const onLeavePictureInPicture = () => {
  videoUIStore.setIsPictureInPictureEnabled(false);

  if (isLive.value || isVOD.value) {
    externalEventBus.emit('picture-in-picture', new VideoPlayerExternalEvent({ active: false }));
  }

  logger.info('VideoStateDetector#leavePictureInPicture');
};

useEffect(() => {
  document.addEventListener('enterpictureinpicture', onEnterPictureInPicture);
  document.addEventListener('leavepictureinpicture', onLeavePictureInPicture);

  return () => {
    document.removeEventListener('enterpictureinpicture', onEnterPictureInPicture);
    document.removeEventListener('leavepictureinpicture', onLeavePictureInPicture);
  };
});
</script>
