import { SubscriptionState, UserType } from '@package/sdk/src/api';
import { isUndefinedOrNull } from '@package/sdk/src/core';
import useDefaultProvide from '@PLAYER/player/modules/instance/use-default-provide';
import { type UserSession } from '@PLAYER/player/modules/session/user';
import { computed, inject, type Ref } from 'vue';

const injectKey = 'payload.session';

export default function useSession() {
  const { getDefault } = useDefaultProvide();

  const session = inject<Ref<UserSession>>(injectKey, getDefault(injectKey));

  const user = computed(() => session.value?.user);
  const offer = computed(() => session.value?.offer);
  const promoOffer = computed(() => session.value?.promoOffer);

  const isAuth = computed(() => !isUndefinedOrNull(user.value));

  const subscription = computed(() => user.value?.subscription);
  const hasOfferTrial = computed(() => offer.value?.trialAvailable);

  const isActiveSubscription = computed(() => subscription.value?.active ?? false);
  const isPartnerSubscription = computed(() => user.value?.userType === UserType.PARTNER);

  const isCanceledSubscription = computed(() => subscription.value?.state === SubscriptionState.Canceled);
  const isInactivePartnerSubscription = computed(() => isPartnerSubscription.value && !isActiveSubscription.value);

  return {
    isInactivePartnerSubscription,
    hasOfferTrial,
    subscription,
    isActiveSubscription,
    isCanceledSubscription,
    isPartnerSubscription,
    user,
    offer,
    promoOffer,
    isAuth,
    session,
  };
}
