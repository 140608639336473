import type { MediaPlayerItemLoaded } from '@package/media-player-analyzer/src/plugin/streaming';
import type { AppEventType } from '@package/sdk/src/core';
import { Disposable, DisposableStore, EventEmitter } from '@package/sdk/src/core';
import AppEvent from '@package/sdk/src/core/event/event';

export class MediaAnalyzerEvent<T> extends AppEvent {
  constructor(public readonly data: T) {
    super();
  }

  public get type(): AppEventType {
    return 'default';
  }
}

export interface MediaAnalyzerEventMap {
  'playback-failed': MediaAnalyzerEvent<'playback-failed'>;
  'playback-interrupted': MediaAnalyzerEvent<'playback-interrupted'>;
  'network-playlist-loaded': MediaAnalyzerEvent<MediaPlayerItemLoaded>;
  'network-fragment-loaded': MediaAnalyzerEvent<MediaPlayerItemLoaded>;
}

export class AbstractMediaAnalyzer extends Disposable {
  public readonly emitter = new EventEmitter<MediaAnalyzerEventMap>();

  protected readonly disposableStore = new DisposableStore();

  constructor() {
    super();
  }
}
