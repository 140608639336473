<template>
  <button type="button" :class="$style.button">
    <slot />
  </button>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.button {
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  background: 0;
  font: inherit;
  text-align: left;
}
</style>
