<template>
  <div
    :class="$style.linkedKinomProgress"
    :style="{
      width: $toPercent(currentProgress * 100),
      left: $toPercent(startProgress * 100),
    }"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  duration: number;
  startOffset: number;
  kinomDuration: number;
}>();

const currentProgress = computed(() => props.kinomDuration / props.duration);
const startProgress = computed(() => props.startOffset / props.duration);
</script>

<style lang="scss" module>
.linkedKinomProgress {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 4px;
  background-color: var(--color-notheme-icon-primary);
}
</style>
