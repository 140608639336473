import AbstractConstantsConfig from './abstract-constants-config';

const CONSTANTS = {
  dsmlPartnerId: '85116679-4837-4638-b307-3d56ac500a6b',
  dsmlPassword: 'hxQkTV,b$e$~bvp=',
  requestTimeoutMs: 5000,
  sendLocalDbEventsTimeoutMs: 20000,
};

class ConstantsConfigDsml extends AbstractConstantsConfig<typeof CONSTANTS> {
  constructor(constantsMap: typeof CONSTANTS) {
    super(constantsMap);
  }
}
const ConstantsConfigInstanceDsml = new ConstantsConfigDsml(CONSTANTS);

export default ConstantsConfigInstanceDsml;
