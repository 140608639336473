<template>
  <root-video-z-index-layer
    :ref="(comp) => setRootElement(comp?.$el)"
    :class="$style.popup"
    fullscreen
    class="popup-layer"
  >
    <component :is="currentPopupComponentName" :ref="(comp) => (popupEl = comp?.$el)" />
  </root-video-z-index-layer>
</template>

<script lang="ts" setup>
import RootVideoZIndexLayer from '@PLAYER/player/components/ui/RootVideoZIndexLayer.vue';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import { onClickOutside } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const layoutStore = useLayoutStore();
const { isWeb } = usePlatform();

const { ignorePopupElements, currentPopupComponentName, isFocused } = storeToRefs(layoutStore);

const popupEl = ref<HTMLElement>();
const rootEl = ref<HTMLElement>();

const setRootElement = (el: HTMLElement) => {
  if (!el) {
    return;
  }

  rootEl.value = el;
};

// При клике вне элемента, закрываем попап. (Для смартов не актуально)
if (isWeb) {
  onClickOutside(
    popupEl,
    () => {
      if (!isFocused.value) {
        return;
      }

      layoutStore.setPopup(null);
    },
    {
      ignore: ignorePopupElements.value,
    },
  );
}
</script>

<style lang="scss" module>
.popup {
  z-index: var(--z-index-popup-view);
  background-color: var(--color-notheme-bg-secondary-20);
  backdrop-filter: var(--g-player-default-backdrop-filter-modal-blur);
  overflow: hidden;
}
</style>
