<template>
  <media-description-layer
    :title="title"
    :subtitle="subtitle"
    :content="content"
    :episode-number-title="episodeNumberTitle"
    :is-adult-content-warning-visible="isAdultContentWarningVisible"
    :is-finger-rewind-tip-visible="isRewindTipNotificationShown"
  />
  <slot />
</template>

<script lang="ts" setup>
import { isUndefinedOrNull } from '@package/sdk/src/core';
import MediaDescriptionLayer from '@PLAYER/player/components/media-information/MediaDescriptionLayer.vue';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useMediaSeasons from '@PLAYER/player/modules/content/use-media-seasons';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { translate } from '@PLAYER/player/modules/localization/translate';
import usePlaylistStore from '@PLAYER/player/modules/store/playlist-store';
import useVideoControlsStore from '@PLAYER/player/modules/store/video-controls-store';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { content } = useContentInformation();
const { isSmartTV } = usePlatform();
const { isSeasonsPresent } = useMediaSeasons();
const { currentPlaylistItem, currentPlaylist } = storeToRefs(usePlaylistStore());
const { isAdultContentWarningVisible } = useVideoPlayerVariables();
const { isRewindTipNotificationShown } = storeToRefs(useVideoControlsStore());

const episodeNumberTitle = computed(() => {
  if (!isSmartTV) {
    return '';
  }

  // Если вдруг это просто фильм
  if (!isSeasonsPresent.value || !currentPlaylist.value || !currentPlaylistItem.value) {
    return '';
  }

  return translate('vod.seasonEpisode.number', {
    season: currentPlaylist.value.number,
    episode: currentPlaylistItem.value.number,
  });
});

const subtitle = computed(() => {
  if (!isSeasonsPresent.value) {
    return '';
  }

  // Если вдруг это просто фильм
  if (!currentPlaylist.value || !currentPlaylistItem.value) {
    return '';
  }

  const season = currentPlaylist.value.number;
  const episode = currentPlaylistItem.value.number;

  // Если это сериал и вдруг нет название эпизода - выводим название сезона/серии
  return translate('episodes.media.episodeNumber', {
    title: content.value.title,
    season,
    episode,
  });
});

const title = computed(() => {
  // Если вдруг нет заголовка
  if (isUndefinedOrNull(content.value?.title)) {
    return '';
  }

  return content.value.title;
});
</script>
