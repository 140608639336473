<template>
  <div>
    <div
      :class="{
        [$style.bar]: true,
        [barClassName]: Boolean(barClassName),
        [$style.barExpanded]: isKinom,
        [$style.barFocused]: isBarFocused,
      }"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import useProjector from '@PLAYER/player/modules/hooks/use-projector';
import { computed } from 'vue';

const { isKinom } = useProjector();
const { isSmartTV } = usePlatform();

const props = defineProps<{
  barClassName?: string;
  isHandleFocused: boolean;
}>();

const isBarFocused = computed(() => {
  if (isSmartTV) {
    return false;
  }

  return props.isHandleFocused;
});
</script>

<style lang="scss" module>
.bar {
  position: relative;
  padding: 0;
  background-color: var(--color-notheme-white-20);
  transition: background-color 0.3s ease;
}

.barFocused {
  background-color: var(--color-notheme-text-primary);
}

.barExpanded {
  height: 5px;
}
</style>
