<template>
  <video
    ref="videoEl"
    :data-video-tag-element-id="ulid()"
    :class="{
      [$style.videoTag]: true,
      [$style.videoObjectCover]: isVideoObjectCover,
      [$style.videoScaled]: isScaled,
      [$style.videoRounded]: isRounded,
      [$style.videoDownscaled]: isVideoDownscaled,
      [$style.videoWithTransition]: isVideoWithTransition,
    }"
    playsinline
    data-video-interaction
    controlslist="nodownload"
    :autoplay="autoplay"
    :poster="usePoster ? posterSrc : ''"
    :loop="loop ? 'true' : undefined"
    :muted="muted"
    :preload="preload"
  />
</template>

<script lang="ts" setup>
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { ulid } from 'ulid';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  isVideoObjectCover?: boolean;
  isVideoDownscaled?: boolean;
  isVideoWithTransition?: boolean;
  autoPictureInPicture?: boolean;
  disablePictureInPicture?: boolean;
  isScaled?: boolean;
  isRounded?: boolean;
  autoplay: boolean;
  loop: boolean;
  muted: boolean;
  preload?: string;
  usePoster?: boolean;
  posterSrc?: string;
  pipPosterHeight?: string;
}>();

const videoEl = ref<HTMLVideoElement>();

onMounted(() => {
  if (!videoEl.value) {
    throw new UnexpectedComponentStateError('videoEl');
  }

  if (props.autoPictureInPicture) {
    videoEl.value.setAttribute('autopictureinpicture', '');
  }

  if (props.disablePictureInPicture) {
    // Для основных браузеров
    videoEl.value.setAttribute('disablepictureinpicture', '');
    // Для Yandex Browser
    videoEl.value.setAttribute('pip', 'false');
  }
});
</script>

<style lang="scss" module>
.videoTag {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;
  user-select: none;
  vertical-align: middle;
  object-fit: contain;

  &:picture-in-picture {
    top: 50%;
    transform: translateY(-50%);
    height: v-bind(pipPosterHeight);
  }
}

.videoObjectCover {
  object-fit: cover;
}

.videoRounded {
  border-radius: var(--g-border-round-24);
}

.videoScaled {
  transform: var(--g-player-default-transform-scale);
}

.videoDownscaled {
  transform: scale(0.98);
  border-radius: var(--g-border-round-32);
}

.videoWithTransition {
  transition: all 0.2s ease;
}
</style>
