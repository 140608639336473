<template>
  <section v-if="moment" :class="{ [$style.poster]: true, [$style.posterSmall]: !isControlShown }">
    <app-image
      :id="moment.id"
      :class="{ [$style.posterImage]: true, [$style.posterImageSmall]: !isControlShown }"
      :src="moment.contentPoster"
      :alt="moment.contentTitle"
      :width="isControlShown ? 106 : 50"
    />
    <div :class="{ [$style.posterControlWrapper]: true, [$style.posterControlWrapperSmall]: !isControlShown }">
      <div :class="$style.posterControl">
        <div :class="$style.posterHeader">
          <p :class="$style.posterText">{{ moment.contentTitle }}</p>
          <p :class="$style.posterHeaderTitle">{{ getTitle(moment) }}</p>
        </div>

        <Transition>
          <div v-show="isControlShown" :class="$style.posterButtons">
            <PlayButton
              :class-name="$style.posterButton"
              :content-type="moment.contentType"
              :content-id="moment.contentId"
              :should-disable-content-when-unavailable="false"
              :moment="moment"
              :extra-props="extraProps"
              :active-class="$style.active"
              variation="primary"
              @active="$emit('active')"
              @on-play-content="onPlayContent"
              @close-modal="onCloseModal"
            />
            <NavigatableItem
              :class="$style.posterButton"
              :tag="AppButton"
              :active-class="$style.active"
              :extra-props="extraProps"
              :text="
                $t('pages.moments.poster.about') + ' ' + $t(`pages.moments.poster.${moment.contentType || 'film'}`)
              "
              @active="$emit('active')"
              @click="onClickAbout(moment)"
            />
          </div>
        </Transition>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { Moment } from '@package/sdk/src/api';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import { FocusKeys, useContentStore, useMediaContentActions } from '@SMART/index';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import PlayButton from '@/pages/media-card/components/PlayButton.vue';

interface Props {
  moment: Moment;
  isControlShown: boolean;
  extraProps?: Record<string, any>;
}

const props = defineProps<Props>();

const emit = defineEmits(['active', 'navigate']);

const { openContentPage } = useMediaContentActions();
const contentStore = useContentStore();

const getTitle = (item: Moment) => [item.contentYear, item.genres?.[0]?.title || ''].filter((x) => !!x).join(', ');

const onPlayContent = () => {
  contentStore.setCurrentLinkedKinom(props.moment);
};

const onClickAbout = (moment: Moment) => {
  emit('navigate');

  const { contentId, contentType, contentTitle, title, seasonNumber, episodeNumber, id } = moment;

  return openContentPage({
    contentType,
    id: contentId,
    title: contentTitle,
    kinomTitle: title,
    kinomId: id,
    seasonNumber,
    episodeNumber,
  });
};

const onCloseModal = () => {
  SpatialNavigation.setFocus(FocusKeys.PLAY_BUTTON);
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.poster {
  position: absolute;
  bottom: adjustPx(48px);
  left: adjustPx(32px);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &Small {
    bottom: adjustPx(32px);
    left: adjustPx(32px);
    width: max-content;
  }

  &Image {
    margin-right: adjustPx(16px);
    width: adjustPx(160px);
    max-width: adjustPx(160px);
    height: adjustPx(240px);
    border-radius: adjustPx(20px);
    outline: none;
    background:
      linear-gradient(
        90.17deg,
        rgba(0, 0, 0, 0.0138889) 0.16%,
        rgba(0, 12, 14, 0.2) 45.21%,
        rgba(0, 0, 0, 0.2) 52.24%,
        rgba(0, 0, 0, 0) 99.87%
      ),
      var(--color-bg-primary);
    scroll-behavior: smooth;
  }

  &ImageSmall {
    width: adjustPx(76px);
    max-width: adjustPx(76px);
    height: adjustPx(115px);
  }

  &Button {
    margin-right: adjustPx(24px) !important;
    height: adjustPx(96px);
    border-color: transparent;
    border-radius: adjustPx(24px);
    background-color: var(--color-bg-secondary-80);
  }

  &Button:hover {
    background: var(--color-bg-accent);
    color: var(--color-notheme-text-accent);
  }

  &Text {
    @include f-subtitle-1;
  }

  &ControlWrapper {
    margin-left: adjustPx(32px);
    height: adjustPx(240px);
  }

  &ControlWrapperSmall {
    height: adjustPx(115px);
  }

  &Control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  &Header {
    display: flex;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--spacing-spacing-none, 0px);
  }

  &Buttons {
    display: flex;
  }

  &HeaderTitle {
    margin-top: adjustPx(4px);
    color: var(--color-notheme-text-secondary);

    @include f-body-3;
  }
}

.active {
  background: var(--color-bg-accent);
  color: var(--color-notheme-text-accent);
}
</style>
