import useLogger from '@package/logger/src/use-logger';
import { isTouchDevice } from '@PLAYER/player/base/dom';
import { onBeforeUnmount, onMounted, type Ref, ref } from 'vue';

const getClientYPosition = (event: MouseEvent | TouchEvent): number => {
  const isMouse = event instanceof MouseEvent;

  return isMouse ? event.clientY : event.changedTouches[0].clientY;
};
const getClientXPosition = (event: MouseEvent | TouchEvent): number => {
  const isMouse = event instanceof MouseEvent;

  return isMouse ? event.clientX : event.changedTouches[0].clientX;
};

interface UseDragOptions {
  onStartCallback?: (event: MouseEvent | TouchEvent) => void;
  onEndCallback?: (event: MouseEvent | TouchEvent) => void;
  preventDefaultOnStart?: boolean;
  isLongTapRewind?: boolean;
}

const logger = useLogger('use-drag', 'smarttv');

export default function useDrag(element: Ref<HTMLElement | undefined>, options?: UseDragOptions) {
  const isDragging = ref(false);
  const mouseXFraction = ref<number | undefined>(undefined);
  const mouseXPixelDiff = ref<number | undefined>(undefined);
  const currentWidth = ref<number | undefined>(undefined);

  const mouseYFraction = ref<number | undefined>(undefined);
  const mouseYPixelDiff = ref<number | undefined>(undefined);
  const currentHeight = ref<number | undefined>(undefined);

  const touchStartX = ref(0);
  const touchStartY = ref(0);
  const touchEndX = ref(0);
  const touchEndY = ref(0);
  const isSingleTouch = ref(false);

  const setStateFromEvent = (event: TouchEvent | MouseEvent) => {
    const pageX = getClientXPosition(event);
    const pageY = getClientYPosition(event);

    if (!element.value) {
      return;
    }

    if (event.type !== 'touchstart') {
      event.preventDefault();
    }

    event.stopPropagation();

    const clientRect = element.value.getBoundingClientRect();
    const xPixelDiff = pageX - clientRect.left;
    const yPixelDiff = pageY - clientRect.top;

    mouseXFraction.value = xPixelDiff / clientRect.width;
    mouseXPixelDiff.value = xPixelDiff;
    currentWidth.value = clientRect.width;

    mouseYFraction.value = yPixelDiff / clientRect.height;
    mouseYPixelDiff.value = yPixelDiff;
    currentHeight.value = clientRect.height;
  };

  const moveHandler = (event: MouseEvent | TouchEvent) => {
    if (!isDragging.value) {
      return;
    }

    setStateFromEvent(event);
  };

  const startHandler = (event: MouseEvent | TouchEvent) => {
    if (options?.isLongTapRewind && event instanceof TouchEvent) {
      touchStartX.value = event.touches[0].clientX;
      touchStartY.value = event.touches[0].clientY;
      isSingleTouch.value = false;
    }

    isDragging.value = true;

    if (options?.onStartCallback) {
      Reflect.apply(options.onStartCallback, undefined, [event]);
    }

    setStateFromEvent(event);
  };

  const releaseHandler = (event: MouseEvent | TouchEvent) => {
    if (options?.isLongTapRewind && event instanceof TouchEvent) {
      touchEndX.value = event.changedTouches[0].clientX;
      touchEndY.value = event.changedTouches[0].clientY;

      const touchDeltaX = Math.abs(touchEndX.value - touchStartX.value);
      const touchDeltaY = Math.abs(touchEndY.value - touchStartY.value);

      if (touchDeltaX < 1 && touchDeltaY < 1) {
        isSingleTouch.value = true;
      }
    }

    if (isDragging.value && options?.onEndCallback) {
      Reflect.apply(options.onEndCallback, undefined, [event]);
    }

    isDragging.value = false;
    mouseXFraction.value = undefined;
    mouseXPixelDiff.value = undefined;
    currentWidth.value = undefined;
    mouseYFraction.value = undefined;
    mouseYPixelDiff.value = undefined;
    currentWidth.value = undefined;
  };

  onMounted(() => {
    if (!element.value) {
      return;
    }

    if (isTouchDevice) {
      element.value.addEventListener('touchstart', startHandler, { passive: false });
      document.addEventListener('touchmove', moveHandler);
      document.addEventListener('touchend', releaseHandler);
    } else {
      element.value.addEventListener('mousedown', startHandler, { passive: false });
      document.addEventListener('mousemove', moveHandler);
      document.addEventListener('mouseup', releaseHandler);
    }
  });

  onBeforeUnmount(() => {
    if (isTouchDevice) {
      element.value?.removeEventListener('touchstart', startHandler);
      document.removeEventListener('touchmove', moveHandler);
      document.removeEventListener('touchend', releaseHandler);
    } else {
      element.value?.removeEventListener('mousedown', startHandler);
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', releaseHandler);
    }
  });

  return {
    isDragging,
    mouseYFraction,
    mouseYPixelDiff,
    currentHeight,
    mouseXFraction,
    mouseXPixelDiff,
    currentWidth,
    isSingleTouch,
  };
}
