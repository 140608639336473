import ConstantsConfigPlayer from '@package/constants/code/constants-config-player';
import { isChromium } from '@PLAYER/player/base/dom';
import { useBattery } from '@vueuse/core';
import { computed, ref } from 'vue';

export default function useDeviceBattery() {
  if (!isChromium) {
    return {
      isBatteryApiSupported: ref(false),
      isLowBatteryMode: ref(false),
      batteryLevel: ref(100),
      batteryDischargingTime: ref(Infinity),
    };
  }

  const { isSupported, level, dischargingTime } = useBattery();

  const isLowBatteryMode = computed(
    () => level.value <= ConstantsConfigPlayer.getProperty('lowBatteryModeThresholdLevel'),
  );

  return {
    isBatteryApiSupported: isSupported,
    batteryLevel: level,
    isLowBatteryMode,
    batteryDischargingTime: dischargingTime,
  };
}
