import { EventEmitter } from '@package/sdk/src/core';

import type { DsmlEventError } from '../platform/errors';
import type { DsmlRequestBodyV2Params } from './dsml-v2-request-transformer';

export interface DsmlEventMap {
  error: DsmlEventError;
  event: DsmlRequestBodyV2Params;
}

const emitter = new EventEmitter<DsmlEventMap>();

export default emitter;
