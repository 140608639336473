<template>
  <section v-if="isSliderShown" ref="container" :class="$style.wrapper">
    <div :class="$style.titleWrapper">
      <UITypography shimmer-variant="subtitle" :class="$style.title" :is-loading="isLoading">
        <span>
          {{ block.title }}
        </span>
        <div v-if="isChannels" :class="$style.live">LIVE</div>
      </UITypography>

      <div v-if="isContinueWatch" :class="$style.titleContinueWatch">
        {{ titleContinueWatch }}
      </div>
    </div>

    <div ref="playlistContainer">
      <scroll-viewport
        tag="ul"
        :class="$style.list"
        :x="offsetLeftPx"
        role="list"
        :is-loading="isLoading"
        shimmer-size="kinom"
      >
        <template v-if="isPromoBlockOrMomentList">
          <li
            v-for="(moment, index) in block.contentMomentsList"
            :key="moment.id"
            :class="{
              [$style.item]: true,
              [$style.itemKinom]: isKinomBlock(block.contentMomentsList),
            }"
            @vue:mounted="emit('items:mounted')"
          >
            <promo-item
              :moment="moment"
              :is-kinom-block="isKinomBlock(block.contentMomentsList)"
              :focus-key="getFocusKey(index)"
              @active="
                (element: HTMLElement) =>
                  handleActiveItem(
                    {
                      item: moment,
                      type: 'content-moment',
                      displayType: block.displayType,
                    },
                    element,
                    index,
                  )
              "
              @focus="onFocus(index)"
              @click="
                openContentPage({
                  contentType: moment.contentType,
                  id: moment.contentId,
                  kinomId: moment.id,
                  kinomTitle: moment.title,
                  episodeNumber: moment.episodeNumber || 0,
                  seasonNumber: moment.seasonNumber || 0,
                  title: moment.contentTitle,
                  playlistTitle: block.title,
                  position: index,
                  from: block.displayType === DisplayType.PromoBlock ? ItemPageFrom.Promo : ItemPageFrom.Playlist,
                })
              "
            />
          </li>
        </template>

        <template v-if="isDsmlRecommendations">
          <li
            v-for="(recommendation, index) in recommendations"
            :key="recommendation.id"
            :class="{
              [$style.item]: true,
              [$style.itemKinom]: isKinomBlock([], recommendations),
            }"
            @vue:mounted="emit('items:mounted')"
          >
            <navigatable-item
              :class="$style.link"
              :active-class="$style.active"
              :tag="AppSlotButton"
              :focus-key="getFocusKey(index)"
              :data-testid="getFocusKey(index)"
              @active="
                (element: HTMLElement) =>
                  handleActiveItem(
                    {
                      item: recommendation,
                      type: 'media',
                      displayType: block.displayType,
                    },
                    element,
                    index,
                  )
              "
              @focus="onFocus(index)"
              @click="
                openContentPage({
                  contentType: recommendation.contentType,
                  id: recommendation.id,
                  title: recommendation.title,
                  from: ItemPageFrom.Dsml,
                  position: index,
                })
              "
            >
              <app-image
                v-if="isKinomBlock([], recommendations)"
                :src="recommendation.background"
                :class="$style.preview"
                :loading="index > 4 ? 'lazy' : 'eager'"
                :width="CONTENT_POSTER_WIDTH"
              />
              <app-image v-else :src="recommendation.poster" :class="$style.poster" :width="CONTENT_POSTER_WIDTH" />
            </navigatable-item>
          </li>
        </template>

        <template v-if="isGenresBelt">
          <li
            v-for="(belt, index) in block.beltItems"
            :key="belt.id"
            :class="[$style.item, $style.itemBelt]"
            :style="{ background: `linear-gradient(to right bottom, ${belt.colors.main}, ${belt.colors.light})` }"
            @vue:mounted="emit('items:mounted')"
          >
            <navigatable-item
              :class="{
                [$style.link]: true,
                [$style.belt]: true,
                [$style.genre]: true,
                [$style.genreWithIcon]: belt.icon,
              }"
              :tag="AppSlotButton"
              :style="{ backgroundColor: belt?.backgroundColor }"
              :focus-key="getFocusKey(index)"
              @active="
                (element: HTMLElement) =>
                  handleActiveItem({ item: belt, type: 'genres-belt', displayType: block.displayType }, element, index)
              "
              @focus="onFocus(index)"
              @click="openCatalogPage(belt)"
            >
              <app-image v-if="belt.icon" :src="belt.icon" :class="$style.beltIcon" />

              <div :class="$style.beltTitle">
                {{ belt.name }}
              </div>
            </navigatable-item>
          </li>
        </template>

        <template v-if="isContinueWatch">
          <li
            v-for="(watchingItem, index) in watchingItems"
            :key="watchingItem.id"
            :class="{
              [$style.item]: true,
              [$style.itemKinom]: isKinomBlock([], watchingItems),
            }"
            @vue:mounted="emit('items:mounted')"
          >
            <navigatable-item
              :class="$style.link"
              :active-class="$style.active"
              tag="div"
              tabindex="0"
              :focus-key="getFocusKey(index)"
              :data-testid="getFocusKey(index)"
              :on-long-press="onOpenContinueWatchEditMode"
              @click="onContinueWatchClick(index)"
              @active="
                (element: HTMLElement) =>
                  handleActiveItem(
                    { type: 'watching-item', item: watchingItem, displayType: block.displayType },
                    element,
                    index,
                  )
              "
              @focus="onFocus(index, watchingItem)"
            >
              <div v-if="isUnavailable(watchingItem) || isAvailableSoon(watchingItem)" :class="$style.overlay">
                <span :class="$style.overlayText">
                  {{ getAvailabilityMessage(watchingItem, { withHourOrMinute: false, withLicenseLockedInfo: false }) }}
                </span>
              </div>

              <app-image
                v-if="isKinomBlock([], watchingItems)"
                :src="watchingItem.background"
                :class="$style.preview"
                :width="CONTENT_POSTER_WIDTH"
              />
              <app-image v-else :src="watchingItem.poster" :class="$style.poster" :width="CONTENT_POSTER_WIDTH" />

              <div
                v-if="isContinueWatchEditMode"
                :class="{
                  [$style.itemDelete]: true,
                  [$style.itemDeleteActive]: selectedPlaylistItem?.item === watchingItem,
                }"
              >
                <icon-delete />
              </div>

              <div
                v-if="!(isUnavailable(watchingItem) || isAvailableSoon(watchingItem))"
                :class="$style.itemProgressWrapper"
              >
                <div :class="$style.itemProgressText">
                  {{ getContinueWatchItemProgressText(watchingItem.duration, watchingItem.watchingItem.offset) }}
                </div>

                <div :class="$style.itemProgress">
                  <div
                    :class="$style.itemProgressDone"
                    :style="{
                      width: getContinueWatchItemProgressWidth(watchingItem.duration, watchingItem.watchingItem.offset),
                    }"
                  />
                </div>
              </div>
            </navigatable-item>
          </li>
        </template>

        <template v-if="isChannels">
          <li
            v-for="(channel, index) in channels"
            :key="channel.id"
            :class="$style.tvItem"
            @vue:mounted="emit('items:mounted')"
          >
            <channel-item
              :channel="channel"
              :index="index"
              :row-index="rowIndex"
              @active="
                (element: HTMLElement) =>
                  handleActiveItem({ item: channel, type: 'channel', displayType: block.displayType }, element, index)
              "
              @focus="onFocus(index)"
            />
          </li>
        </template>
      </scroll-viewport>
    </div>
  </section>
</template>

<script setup lang="ts">
import useMediaContentAvailability from '@package/content-utils/src/code/use-media-content-availability';
import { ItemPageFrom, useCatalogPageAnalytics, useMainPageAnalytics } from '@package/sdk/src/analytics';
import {
  type Channel,
  type ContentMoment,
  DisplayType,
  type GenresBeltItem,
  type MainPageBlock,
  type Media,
  MediaContentType,
} from '@package/sdk/src/api';
import { indexOutOfRange, toPercent, TvKeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useListNavigationActions from '@package/smarttv-base/src/navigation/use-list-navigation-actions';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import IconDelete from '@SMART/assets/icons/33x33/delete.svg';
import type { SessionGetters, SessionState } from '@SMART/index';
import {
  analyticService,
  catalogService,
  customEventsService,
  FocusKeys,
  keyboardEventHandler,
  RouterPage,
  routerService,
  scrollToElement,
  storeToRefs,
  translate,
  useMainPageStore,
  useMediaContentActions,
  useSessionStore,
  useSessionVariables,
  useSliderOffset,
  useSmartTVContentAvailability,
} from '@SMART/index';
import { intervalToDuration } from 'date-fns';
import { computed, nextTick, provide, ref, watch } from 'vue';

import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import NavigatableItem from '@/components/navigation/NavigatableItem.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import UITypography from '../typography/UITypography.vue';
import ChannelItem from './components/ChannelItem.vue';
import PromoItem from './components/PromoItem.vue';

interface Props {
  block: MainPageBlock;
  recommendations: Media[];
  watchingItems: Media[];
  rowIndex: number;
  channels: Channel[];
  wrapper?: HTMLElement;
  isLoading?: boolean;
}

const props = defineProps<Props>();

const mainPageStore = useMainPageStore();

const CONTENT_POSTER_WIDTH = 400;

const { openContentPage, openPlayerPage } = useMediaContentActions();
const catalogPageAnalytics = useCatalogPageAnalytics(analyticService.sender);
const mainPageAnalytics = useMainPageAnalytics(analyticService.sender);
const { isUnavailable, isAvailableSoon } = useMediaContentAvailability();
const { getAvailabilityMessage } = useSmartTVContentAvailability();
const { handleUpdateOffset, offsetLeftPx } = useSliderOffset();
const {
  focusKey,
  el: container,
  addFocusable,
  updateFocusable,
  removeFocusable,
} = useNavigatable({
  focusKey: FocusKeys.PLAYLIST_SLIDER(props.rowIndex),
  saveLastFocusedChild: true,
  autoRestoreFocus: true,
  isFocusBoundary: true,
  focusBoundaryDirections: ['right'],
  onFocus: () => {
    switch (props.block.displayType) {
      case DisplayType.GenresBelt:
        mainPageAnalytics.onGenresBeltListDisplayed();
        break;
      case DisplayType.DsmlRecommendations:
        mainPageAnalytics.onDsmlRecommendationsBlockDisplayed();
        break;
    }
  },
});
provide('parentFocusKey', focusKey.value);

const getFocusKey = (col?: string | number) => FocusKeys.PLAYLIST_ITEM(props.rowIndex, col);
const playlistList = useListNavigationActions(getFocusKey);

const playlistContainer = ref<HTMLElement>();

const isSliderShown = computed(() => {
  if (
    [DisplayType.PromoBlock, DisplayType.MomentList, DisplayType.GenresBelt, DisplayType.ContentMomentList].includes(
      props.block?.displayType,
    )
  ) {
    return true;
  }

  if (props.block?.displayType === DisplayType.TvChannelsList && props.channels.length) {
    return true;
  }

  if (props.block?.displayType === DisplayType.ContinueWatch && props.watchingItems.length) {
    return true;
  }

  if (props.block?.displayType === DisplayType.DsmlRecommendations && props.recommendations.length) {
    return true;
  }

  return false;
});

watch(
  () => isSliderShown.value,
  async (value) => {
    if (!value) {
      removeFocusable();
      return;
    }

    // Белты приходится добавлять вручную в навигицию, т.к. грузятся они не очередно
    // Пока решение такое, но по возможности поправим
    await nextTick();

    addFocusable();
    updateFocusable();
  },
);

const isContinueWatchEditMode = ref(false);
const selectedPlaylistItem = ref<{ item: Media; focusKey: string } | undefined>();
const { profile } = storeToRefs<SessionState, SessionGetters, unknown>(useSessionStore());

const { isInactivePartnerSubscription } = useSessionVariables();

const isContinueWatch = computed(() => props.block?.displayType === DisplayType.ContinueWatch);
const isChannels = computed(() => props.block?.displayType === DisplayType.TvChannelsList);

const isPromoBlockOrMomentList = computed(() =>
  [DisplayType.PromoBlock, DisplayType.MomentList, DisplayType.ContentMomentList].includes(props.block?.displayType),
);

const isDsmlRecommendations = computed(() => props.block?.displayType === DisplayType.DsmlRecommendations);
const isGenresBelt = computed(() => props.block?.displayType === DisplayType.GenresBelt);

export interface ActivatedItemEvent {
  item: ContentMoment | Media | GenresBeltItem | Channel;
  type: 'content-moment' | 'channel' | 'genres-belt' | 'media' | 'watching-item';
  displayType: DisplayType;
}

const emit = defineEmits<{
  (event: 'items:mounted'): void;
  (event: 'activated', item: ActivatedItemEvent, offset: number): void;
  (event: 'update:watching-items', focusKey: string): void;
}>();

const onFocus = (index: number, watchingItem?: Media) => {
  const focusKey = getFocusKey(index);
  mainPageStore.updateSelectedBeltItem({ index, id: watchingItem?.id ?? '', focusKey });
};

const handleActiveItem = (data: ActivatedItemEvent, element: HTMLElement, index: number) => {
  if (props.wrapper) {
    scrollToElement(props.wrapper, { top: container.value.offsetTop });
  }

  const { item, displayType } = data;

  const focusKey = getFocusKey(index);
  const currentFocusKey = selectedPlaylistItem.value?.focusKey;

  selectedPlaylistItem.value = { item: item as Media, focusKey };

  handleUpdateOffset(element, playlistContainer.value!.offsetWidth);

  emit('activated', data, container.value?.offsetTop || 0);
  mainPageStore.updateSelectedBeltItem({ index, id: item.id, focusKey });

  if (focusKey && currentFocusKey && focusKey !== currentFocusKey) {
    const direction = playlistList.getScrollDirection(currentFocusKey);

    if (isDsmlRecommendations.value && offsetLeftPx.value) {
      mainPageAnalytics.onDsmlRecommendationsBlockSwiped(direction);
      return;
    }

    if (direction === 'left') {
      switch (displayType) {
        case DisplayType.PromoBlock:
          mainPageAnalytics.onPromoHeaderSliderSwipedLeft();
          break;
        case DisplayType.ContinueWatch:
          mainPageAnalytics.onContinueWatchItemBlockSwipedLeft();
          break;
      }
    }
  }
};

const onContinueWatchClick = async (index: number) => {
  if (isContinueWatchEditMode.value) {
    return onRemoveContinueWatchItem();
  }

  if (!selectedPlaylistItem.value) {
    return;
  }

  const { contentType, title, serialId, id } = selectedPlaylistItem.value.item;

  const episodeId = contentType !== MediaContentType.Movie ? id : undefined;
  const normalizedContentType =
    contentType === MediaContentType.Movie ? MediaContentType.Movie : MediaContentType.Serial;
  const normalizedId = contentType !== MediaContentType.Movie ? (serialId as string) : id;

  if (isInactivePartnerSubscription.value || isUnavailable(selectedPlaylistItem.value.item)) {
    return openContentPage({
      episodeId,
      from: ItemPageFrom.ContinueWatch,
      contentType: normalizedContentType,
      id: normalizedId,
      position: index,
      title,
    });
  }

  return openPlayerPage({
    episodeId,
    contentType: normalizedContentType,
    id: normalizedId,
    title,
  });
};

const openCatalogPage = (belt: GenresBeltItem) => {
  catalogPageAnalytics.onGotoCatalogPage();

  return routerService.push({
    name: RouterPage.CatalogPage,
    query: {
      genre: belt.genresSlugs,
    },
  });
};

const onOpenContinueWatchEditMode = () => {
  isContinueWatchEditMode.value = true;

  customEventsService.setOnPressBackCallback(() => {
    isContinueWatchEditMode.value = false;
  }, true);
};

const onRemoveContinueWatchItem = async () => {
  if (!selectedPlaylistItem.value) {
    throw new UnexpectedComponentStateError('selectedContinueWatchingItem');
  }

  await catalogService.hideWatchItem(profile.value.id, selectedPlaylistItem.value.item.id);

  emit('update:watching-items', selectedPlaylistItem.value.item.id);

  selectedPlaylistItem.value = undefined;
};

const titleContinueWatch = computed(() => {
  return isContinueWatchEditMode.value
    ? translate('pages.main.playlists.continueWatchReturn')
    : translate('pages.main.playlists.continueWatchEdit');
});

const getContinueWatchItemProgressWidth = (duration: number, offset: number): string => {
  return toPercent(Math.ceil(offset / (duration / 100)));
};

const getContinueWatchItemProgressText = (duration: number, offset: number): string => {
  const { hours, minutes } = intervalToDuration({ start: 0, end: (duration - offset) * 1000 });

  if (hours) {
    return translate('pages.main.remainTime', { hours, minutes: minutes ?? '' });
  }

  return translate('pages.main.remainTimeShort', { minutes: minutes ?? '' });
};

const isKinomBlock = (moments: ContentMoment[] = [], media: Media[] = []): boolean => {
  for (let i = 0; i < moments.length; i++) {
    const moment = moments[i];
    const index = moments.findIndex((item, index) => index !== i && item.contentId === moment.contentId);

    if (!indexOutOfRange(index)) {
      return true;
    }
  }

  for (let i = 0; i < media.length; i++) {
    const data = media[i];
    const index = media.findIndex((item, index) => index !== i && item.id === data.id);

    if (!indexOutOfRange(index)) {
      return true;
    }
  }

  return false;
};

keyboardEventHandler.on(TvKeyCode.RETURN, () => {
  if (!isContinueWatchEditMode.value) {
    return;
  }

  isContinueWatchEditMode.value = false;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/smarttv-fonts.scss' as smartTvFonts;
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  margin-bottom: adjustPx(40px);
}

.title {
  @include smartTvFonts.SmartTvSubtitle-1();
  display: flex;
  align-items: center;

  &Wrapper {
    margin-bottom: adjustPx(40px);
  }

  &ContinueWatch {
    color: var(--color-text-primary);

    @include smartTvFonts.SmartTvBody-3();
  }
}

.list {
  display: flex;
}

.item {
  position: relative;
  margin-right: adjustPx(24px);
  min-width: adjustPx(296px);
  max-width: adjustPx(296px);
  height: adjustPx(400px);
  border-radius: adjustPx(24px);
  outline: none;
  background: var(--color-bg-modal);
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }

  &Kinom {
    min-width: adjustPx(370px);
    max-width: adjustPx(370px);
    height: adjustPx(224px);
  }

  &Belt {
    min-width: adjustPx(332px);
    max-width: adjustPx(332px);
    min-height: adjustPx(300px);
    max-height: adjustPx(300px);
  }

  &Delete {
    position: absolute;
    top: adjustPx(20px);
    right: adjustPx(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: adjustPx(16px);
    border-radius: adjustPx(16px);
    background: var(--color-bg-button-secondary);

    &Active {
      background-color: var(--color-bg-accent);
      color: var(--color-notheme-icon-accent);
    }
  }

  &Progress {
    position: absolute;
    bottom: adjustPx(28px);
    left: adjustPx(28px);
    right: adjustPx(28px);
    z-index: map-get($map: $layers, $key: --z-index-heading);
    height: adjustPx(6px);
    border-radius: adjustPx(8px);
    background-color: var(--color-notheme-white-20);
    overflow: hidden;

    &Wrapper {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: adjustPx(200px);
        background-image: url('@SMART/assets/images/progress-gradient.png');
        background-repeat: no-repeat;
      }
    }

    &Text {
      position: absolute;
      bottom: adjustPx(38px);
      right: adjustPx(28px);
      z-index: map-get($map: $layers, $key: --z-index-heading);
      color: var(--color-text-secondary);

      @include smartTvFonts.SmartTvBody-3();
    }

    &Done {
      height: adjustPx(6px);
      border-radius: adjustPx(8px);
      background-color: var(--color-bg-accent);
    }
  }
}

.belt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: adjustPx(20px);
  min-width: adjustPx(332px);
  max-width: adjustPx(332px);
  min-height: adjustPx(300px);
  max-height: adjustPx(300px);

  &Icon {
    margin-bottom: adjustPx(24px);
    width: adjustPx(98px);
    min-height: adjustPx(98px);
    max-height: adjustPx(98px);
  }

  &Title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: center;
  }
}

.genreWithIcon {
  justify-content: normal !important;
  padding-top: adjustPx(74px);
}

.tvItem {
  margin-right: adjustPx(8px);

  outline: none;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjustPx(24px);
    content: '';
  }

  &:hover::after,
  &.active::after {
    box-shadow: inset 0 0 0 adjustPx(8px) var(--color-bg-accent);
  }
}

.preview {
  transform: scale(1.35, 1.35);
  opacity: 1;
  overflow: hidden;
}

.poster {
  transform: none;
  opacity: 1;
  overflow: hidden;
}

.live {
  @include smartTvFonts.SmartTvLabel-3();

  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-left: adjustPx(16px);
  padding: adjustPx(4px) adjustPx(16px);
  height: adjustPx(48px);
  border-radius: adjustPx(12px);
  background: var(--gradient-notheme-red-bottom-to-top);
  color: var(--color-notheme-text-primary);
}

.tv {
  position: relative;
  width: 100%;
  height: 100%;

  .header {
    position: absolute;
    bottom: adjustPx(70px);
    width: 100%;
    height: adjustPx(108px);
    background: linear-gradient(0deg, rgba(22, 21, 26, 1) 0%, rgba(22, 21, 26, 0) 100%);
  }

  .done {
    position: absolute;
    bottom: adjustPx(70px);
    width: 50px;
    height: adjustPx(1px);
    border: adjustPx(4px) solid;
    border-color: var(--color-bg-accent);
    color: var(--color-bg-accent);
  }

  .footer {
    position: absolute;
    bottom: 0;
    padding: adjustPx(16px) adjustPx(24px);
    width: 100%;
    height: adjustPx(70px);
    background: linear-gradient(180deg, rgba(22, 21, 26, 1) 10.95%, rgba(22, 21, 26, 0) 100%);
    overflow: hidden;
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .badge {
    position: absolute;
    top: adjustPx(16px);
    right: adjustPx(16px);
    color: var(--color-bg-accent);

    @include f-caption;
  }

  .logo {
    position: absolute;
    bottom: adjustPx(85px);
    left: adjustPx(24px);
    width: adjustPx(202px) !important;
    height: adjustPx(68px) !important;
    object-fit: contain;
  }

  .title {
    position: absolute;
    bottom: adjustPx(85px);
    left: adjustPx(24px);
    color: var(--color-text-primary);

    @include f-caption;
  }

  .text {
    margin-left: adjustPx(12px);
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;

    @include f-caption;
  }
}

.icon {
  transform: scale(0.5, 0.5);
  min-height: fit-content;
  opacity: 1;
  overflow: hidden;
}

img.icon {
  position: absolute;
  top: -25%;
  left: 0;
  min-width: adjustPx(350px);
  height: fit-content;
  min-height: adjustPx(350px);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-notheme-dim-black-60);
  color: var(--color-text-secondary);

  @include smartTvFonts.SmartTvBody-3();

  &Text {
    max-width: adjustPx(194px);
    text-align: center;
  }
}
</style>
