<template>
  <div :class="$style.freeEpisodeTooltipWrapper">
    <div :class="$style.freeEpisodeTooltip">
      <div :class="$style.imageWrapper">
        <app-image
          v-if="isImageLoaded"
          :image-class="$style.image"
          :width="232"
          background-color="var(--color-notheme-bg-hover-80)"
          :src="episode.preview"
        />
        <div v-else :class="$style.loader">
          <icon-preloader :class="$style.icon" is-light-mode />
        </div>
        <p :class="$style.imageText">{{ $t('media.freeContent.availableAfterSubscription') }}</p>
      </div>

      <p :class="$style.episodeNumber">{{ $t('media.freeContent.episodeNumber', { number: episode.number }) }}</p>
      <p :class="$style.episodeTitle">{{ getContentTitle(episode.title) }}</p>
      <p :class="$style.episodeDescription">{{ getContentDescription(episode.description, maxDescriptionLength) }}</p>
      <free-episode-subscribe-button :episode="episode" :class="$style.button" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Episode } from '@package/sdk/src/api';
import { isMobile } from '@PLAYER/player/base/dom';
import IconPreloader from '@PLAYER/player/components/icons/IconPreloader.vue';
import FreeEpisodeSubscribeButton from '@PLAYER/player/components/media-information/FreeEpisodeSubscribeButton.vue';
import AppImage from '@PLAYER/player/components/ui/AppImage.vue';
import { usePlayerTexts } from '@PLAYER/player/modules/hooks/use-player-texts';
import { ref } from 'vue';

defineProps<{
  episode: Episode;
}>();

const { getContentTitle, getContentDescription } = usePlayerTexts();

const maxDescriptionLength = isMobile ? 48 : 64;

const isImageLoaded = ref(true);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.freeEpisodeTooltipWrapper {
  padding-bottom: var(--g-spacing-32);
}

.freeEpisodeTooltip {
  --tooltip-width-image: 192px;
  padding: var(--g-spacing-20);

  min-width: 232px;
  border-radius: var(--g-border-round-16);
  background: var(--color-notheme-bg-secondary-80);
  backdrop-filter: var(--g-player-default-backdrop-filter-modal-blur);
}

.image {
  border-radius: var(--g-border-round-8);
  filter: brightness(60%);
}

.loader {
  width: 160px;
  border-radius: var(--g-border-round-32);
  background-color: var(--color-state-bg-tertiary-pressed);
}

.icon {
  width: 100% !important;
}

.imageWrapper {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--g-spacing-16);
}

.imageText {
  position: absolute;
  text-align: center;
  padding: var(--g-spacing-40);

  @include webFonts.WebLabel-3();
}

.episodeNumber {
  color: var(--color-notheme-text-tertiary);

  @include webFonts.WebBody-3();
}

.episodeTitle {
  @include webFonts.WebLabel-1();
}

.episodeDescription {
  @include webFonts.WebBody-3();
}

.button {
  margin-top: var(--g-spacing-16);
  width: 100%;
}

@container rootVideo (max-width: 1024px) {
  .freeEpisodeTooltipWrapper {
    margin-bottom: -32px;
  }

  .freeEpisodeTooltip {
    padding: var(--g-spacing-12);
    max-width: 216px;
  }
}

@container rootVideo (min-width: 1024px) {
  .imageWrapper {
    display: flex;
  }
}
</style>
