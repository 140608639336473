<template>
  <slot />
</template>

<script lang="ts" setup>
import { EventEmitter, KeyboardHandler } from '@package/sdk/src/core';
import usePlatform from '@PLAYER/player/modules/hooks/use-platform';
import { MouseHandler } from '@PLAYER/player/modules/mouse/mouse-handler';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';

const { isWeb } = usePlatform();
const { isInitialized } = storeToRefs(useVideoUIStore());

const currentVideoSessionCounter = ref(0);

const mouse = new MouseHandler();
const keyboard = new KeyboardHandler({
  eventPropertyName: isWeb ? 'code' : 'keyCode',
});

const eventBus = new EventEmitter();

provide('handler.mouse', mouse);
provide('handler.keyboard', keyboard);
provide('app.emitter.internal', eventBus);

provide('app.video.session.counter', currentVideoSessionCounter);

let currentVideoSessionTimeoutId: number;

const startTickCurrentVideoSessionCounter = () => {
  currentVideoSessionCounter.value += 1;

  currentVideoSessionTimeoutId = window.setTimeout(() => startTickCurrentVideoSessionCounter(), 1000);
};

const resetCurrentVideoSessionCounter = () => {
  if (currentVideoSessionTimeoutId) {
    window.clearTimeout(currentVideoSessionTimeoutId);
  }

  currentVideoSessionCounter.value = 0;
};

watch(isInitialized, (isInit) => {
  if (isInit) {
    return startTickCurrentVideoSessionCounter();
  }

  resetCurrentVideoSessionCounter();
});

onMounted(() => {
  // Реагируем только на нажатия через keydown
  keyboard.init({ keydown: true, keyup: false });
});

onBeforeUnmount(() => {
  if (currentVideoSessionTimeoutId) {
    window.clearTimeout(currentVideoSessionTimeoutId);
  }

  if (document.documentElement.classList.contains('no-scroll')) {
    document.documentElement.classList.remove('no-scroll');
  }

  mouse.dispose();
  keyboard.dispose();
});
</script>
