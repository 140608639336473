import useSafeRootVideoElement from '@PLAYER/player/modules/video/use-safe-root-video-element';
import { useElementSize } from '@vueuse/core';

export default function useRootVideoElBounds() {
  const rootVideoEl = useSafeRootVideoElement();
  const { width, height } = useElementSize(rootVideoEl);

  return {
    appWidth: width,
    appHeight: height,
  };
}
