import ConstantsConfigDsml from '@package/constants/code/constants-config-dsml';
import useLogger from '@package/logger/src/use-logger';
import { getDetailedDeviceInfo, type IDisposable, UnexpectedComponentStateError } from '@package/sdk/src/core';
import getUserAgentData from '@package/sdk/src/core/device/get-user-agent-data';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import { ulid } from 'ulid';

import { version } from '../../package.json';
import transformBodyToV1Version from '../core/code/dsml-v1-request-transformer';
import transformBodyToV2Version from '../core/code/dsml-v2-request-transformer';
import type { DsmlRequestBodyV2Params } from './code/dsml-v2-request-transformer';
import { EventBuffer, singleEventBuffer } from './code/event-buffer';
import emitter, { type DsmlEventMap } from './code/event-emitter';
import getActualToken from './code/get-actual-token';
import type { ClientType, Environment, PartnerId, StorageType, UserPayload } from './code/user';
import getParsedUTMValues from './code/utm';
import { generateUuid } from './code/uuid';
import { StoredEventsDatabase } from './database/stored-events-database';
import { DsmlEventError } from './platform/errors';
import memoryStorage from './platform/memory-storage';
import BadResponseError from './request/api/errors/BadResponseError';
import RequestNetworkProblemError from './request/api/errors/RequestNetworkProblemError';
import createToken, { type TokenResponseData } from './request/create-token-request';
import { sendDsmlEvent } from './request/send-single-event-request';
import updateToken from './request/update-token-request';

export interface DsmlValue {
  property: string;
  value: unknown;
}

export interface DsmlAdditionalOptions {
  itemId?: string;
  episodeId?: string;
  kinomId?: string;
  timecode?: number;
}

export interface SendEventOptions {
  name: string;
  values: DsmlValue[];
  page?: string;
  options?: DsmlAdditionalOptions;
  eventSenderMode?: DSMLOptions['eventSenderMode'];
}

export interface DSMLOptions {
  partnerId: PartnerId;
  clientType: ClientType;
  variation?: 'ru' | 'am';
  password: string;
  storageType?: StorageType;
  appVersion?: string;
  debugMode?: boolean;
  eventSenderMode?: 'default' | 'stored';
}

interface LoggerType {
  log: (name: string, values?: DsmlValue[], payload?: DsmlAdditionalOptions) => void;
  warn: (error: unknown) => void;
  error: (error: unknown) => void;
}

export interface DSMLAnalyticApi {
  // events
  addEventListener<T extends keyof DsmlEventMap>(event: T, listener: (arg: DsmlEventMap[T]) => void): IDisposable;

  // public api
  sendEvent(data: SendEventOptions): this;

  // init
  init(options: DSMLOptions): Promise<this>;

  // set user payload
  setUser(payload: UserPayload): this;

  // set environment
  setEnvironment(env: Environment): this;

  // set external logger
  setLogger(logger: LoggerType): this;

  // set flags
  setFeatureFlags(flags: Record<string, string | boolean | number>): this;

  // reset flags
  resetFeatureFlags(): this;
}

const logger = useLogger('dsmlApi', 'dsml-js');
logger.level = -999;

const isClient = typeof window !== 'undefined';

if (!isClient) {
  console.info('%c INFO', 'color: #33f', 'dsml.js version - ' + version);
}

export default class DsmlApi implements DSMLAnalyticApi {
  private refreshTokenPromise: Promise<TokenResponseData> | null = null;
  public version: string = version;

  private db: StoredEventsDatabase | undefined;

  constructor() {
    window.addEventListener('DOMContentLoaded', this.initialize);
  }

  private initialize() {
    const utmValues = getParsedUTMValues();

    if (utmValues.length > 0) {
      memoryStorage.set('utmValues', utmValues);
    }

    window.removeEventListener('DOMContentLoaded', this.initialize);
  }

  private async _handleBadResponseError(error: BadResponseError, event: DsmlRequestBodyV2Params): Promise<void> {
    const { response } = error;
    const { status } = response;

    const handledStatuses = Object.values(HTTPStatusCode);
    type StatusCodeHandler = Record<HTTPStatusCode.Unauthorized | HTTPStatusCode.Forbidden, Function>;

    const statusCodeHandlers: StatusCodeHandler = {
      [HTTPStatusCode.Unauthorized]: updateToken,
      [HTTPStatusCode.Forbidden]: createToken,
    };

    if (!handledStatuses.includes(status)) {
      const eventId = ulid();

      if (!this.db) {
        return;
      }

      return this.db.add(eventId, event);
    }

    const { event_name, payload } = event;

    if (status === HTTPStatusCode.UnprocessableEntity) {
      logger.error(`Unprocessable entity: event '${event_name}' called with ${JSON.stringify(payload)}`);
      return;
    }

    if (status === HTTPStatusCode.InternalServerError) {
      logger.error(
        `Internal server error occured while processing '${event_name}' called with ${JSON.stringify(payload)}`,
      );
      return;
    }

    if (this.refreshTokenPromise) {
      const result = await this.refreshTokenPromise;

      if (!result.access_token) {
        logger.error(`Cannot refresh dsml token: ${result}`);
        return;
      }

      await this._sendEvent(event);
      return;
    }

    const handler = statusCodeHandlers[status as HTTPStatusCode.Unauthorized | HTTPStatusCode.Forbidden];

    if (!handler) {
      return;
    }

    try {
      this.refreshTokenPromise = await handler(await getActualToken());
      await this._sendEvent(event);
      this.refreshTokenPromise = null;
    } catch (error) {
      this.refreshTokenPromise = createToken();
      await this.refreshTokenPromise;
      await this._sendEvent(event);
      this.refreshTokenPromise = null;
    }
  }

  private _validateEvent(data: SendEventOptions): void {
    const { name, values, options, page } = data;

    if (!page) {
      console.warn("sendEvent: 'page' should be a non-empty string");
    }

    if (!name) {
      console.warn("sendEvent: 'name' should be a non-empty string");
    }

    if (!Array.isArray(values)) {
      console.warn("sendEvent: 'values' should be an array");
    }

    if (typeof options !== 'object' || Array.isArray(options) || options === null) {
      console.warn("sendEvent: 'options' should be an object");
    }
  }

  private _enrichEventPayload(values: DsmlValue[]): void {
    // Добавляем везде отправку utm_меток сохраненных
    if (memoryStorage.has('utmValues')) {
      const utmValues = memoryStorage.get('utmValues') as DsmlValue[];

      values.push(...utmValues);
    }

    if (memoryStorage.has('featureFlags')) {
      const flagValues = memoryStorage.get('featureFlags');

      values.push({ property: 'flags', value: flagValues });
    }

    values.push({ property: 'url', value: window.location.href });
  }

  private prepareEvent(
    name: string,
    page: string,
    values: DsmlValue[],
    options?: DsmlAdditionalOptions,
  ): DsmlRequestBodyV2Params {
    this._enrichEventPayload(values);

    const bodyV1 = transformBodyToV1Version(name, page, values, options);
    return transformBodyToV2Version(bodyV1, page);
  }

  private async _sendEvents(events: DsmlRequestBodyV2Params[]) {
    if (!this.db) {
      return;
    }

    try {
      const buffer = new EventBuffer(events);
      await buffer.sendAll();
    } catch (e) {
      logger.error(e);
      await this.db.clear();
    }
  }

  private async _sendEvent(event: DsmlRequestBodyV2Params): Promise<void> {
    try {
      await sendDsmlEvent(event);

      emitter.emit('event', event);

      // Если в временном буфере были события - также отправляем их в API
      if (singleEventBuffer.length > 0) {
        singleEventBuffer.sendAll();
      }
    } catch (error) {
      if (error instanceof BadResponseError) {
        return this._handleBadResponseError(error, event);
      }

      if (error instanceof DsmlEventError) {
        return emitter.emit('error', error);
      }

      if (error instanceof RequestNetworkProblemError) {
        const eventId = ulid();
        return this._sendDbEvent(eventId, event);
      }

      logger.error(error);
    }
  }

  private _sendDbEvent(id: string, event: DsmlRequestBodyV2Params) {
    if (!this.db) {
      return;
    }

    return this.db.add(id, event);
  }

  public addEventListener<T extends keyof DsmlEventMap>(event: T, listener: (arg: DsmlEventMap[T]) => void) {
    return emitter.on(event, listener);
  }

  public setUser(payload: UserPayload): this {
    // user id
    memoryStorage.set('userId', payload.userId);

    // user profileId
    memoryStorage.set('profileId', payload.profileId);

    if (payload.visitorId) {
      memoryStorage.set('visitorId', payload.visitorId);
    }

    if (payload.userIpV4) {
      memoryStorage.set('userIpV4', payload.userIpV4);
    }

    if (payload.userIpV6) {
      memoryStorage.set('userIpV6', payload.userIpV6);
    }

    return this;
  }

  public setEnvironment(env: Environment): this {
    memoryStorage.set('env', env || 'development');

    return this;
  }

  /**
   * @deprecated
   *
   * @description No longer support now.
   *
   * @param {LoggerType} _
   * @return {this}
   */
  public setLogger(_: LoggerType): this {
    return this;
  }

  public resetFeatureFlags(): this {
    memoryStorage.set('featureFlags', undefined);

    return this;
  }

  public setFeatureFlags(flags: Record<string, string | boolean | number>): this {
    const addedFlags = memoryStorage.get('featureFlags') || {};

    const updatedFlags = {
      // @ts-ignore
      ...addedFlags,
      ...flags,
    };

    memoryStorage.set('featureFlags', updatedFlags);

    return this;
  }

  public async init(options: DSMLOptions): Promise<this> {
    const uuid = generateUuid();

    const deviceInfo = await getDetailedDeviceInfo();
    const { isSupported, model, platformVersion, platformName } = await getUserAgentData();

    const eventSenderMode: DSMLOptions['eventSenderMode'] = options.eventSenderMode || 'default';

    const { osVersion, browserVersion, browserName, osName, vendorName, modelName, modelCode, isSmartTV } = deviceInfo;

    // Версия браузера: Google Chrome v.133
    const normalizedBrowserVersion = `${browserName} v.${browserVersion}`;

    // Версия ОС: MacOS v.14.5 / Windows v.10
    const normalizedOsVersion = (() => {
      if (isSupported && !isSmartTV) {
        return `${platformName} v.${platformVersion}`;
      }

      return `${osName} v.${osVersion}`;
    })();

    // Версия устройства:
    // Samsung / FN-23232 / DD22AD1
    // LG / NANOT6AB / DD444ADCB1
    const normalizedDeviceType = (() => {
      if (isSupported && !isSmartTV) {
        return `${vendorName} / ${model || modelName} / ${modelCode}`;
      }

      return `${vendorName} / ${modelName} / ${modelCode}`;
    })();

    memoryStorage.set('osVersion', normalizedOsVersion);
    memoryStorage.set('browserVersion', normalizedBrowserVersion);
    memoryStorage.set('deviceType', normalizedDeviceType);

    memoryStorage.set('sessionId', uuid);

    memoryStorage.set('partnerId', options.partnerId);
    memoryStorage.set('clientType', options.clientType);
    memoryStorage.set('password', options.password);
    memoryStorage.set('appVersion', options.appVersion);

    memoryStorage.set('storageType', options.storageType || 'cookie');
    memoryStorage.set('appVariation', options.variation || 'ru');

    memoryStorage.set('debugMode', options.debugMode || false);
    memoryStorage.set('eventSenderMode', eventSenderMode);

    // Инициализация встроенной БД для хранения событий
    this.db = new StoredEventsDatabase();
    const events = await this.db.readAll();

    if (events && events.length > 0) {
      logger.log('dsml-js#init', 'Loading to api stored events from local db');

      const formattedEvents = events.map((event) => event.value);

      await this.sendEvents(formattedEvents);
    }

    this.initializeIntervalSender();

    return this;
  }

  public initializeIntervalSender() {
    if (!isClient) {
      return;
    }

    const sendEventsByInterval = async () => {
      if (!this.db) {
        return;
      }

      const events = await this.db.readAll();

      if (events && events.length > 0) {
        const formattedEvents = events.map((event) => event.value);

        await this.sendEvents(formattedEvents);
      }
    };

    window.setInterval(sendEventsByInterval, ConstantsConfigDsml.getProperty('sendLocalDbEventsTimeoutMs'));
  }

  public sendEvents(events: DsmlRequestBodyV2Params[]) {
    return this._sendEvents(events);
  }

  public sendEvent(event: SendEventOptions): this {
    const { name, values, options, page = '' } = event;

    this._validateEvent(event);

    if (!name && memoryStorage.get('debugMode')) {
      throw new UnexpectedComponentStateError('event.name');
    }

    const transformedEvent = this.prepareEvent(name, page, values, options);

    if (this.db && event.eventSenderMode === 'stored') {
      const eventId = ulid();

      this._sendDbEvent(eventId, transformedEvent);
    } else {
      this._sendEvent(transformedEvent);
    }

    return this;
  }
}
