<template>
  <div ref="el" :class="$style.wrapper">
    <AppMenu v-if="!isMenuHidden" ref="appMenuRef" :key="menuKey" />
    <AppBuildBadge v-if="isBuildBadgeShown" />

    <div :class="{ [$style.pageMenuExpanded]: hasFocusedMenu, [$style.page]: true }">
      <RouterView v-slot="{ Component }">
        <KeepAlive
          :key="cacheKey"
          :data-key="cacheKey"
          :include="[
            $RouterPage.CatalogPage,
            $RouterPage.CollectionPage,
            $RouterPage.SearchPage,
            $RouterPage.ChannelsPage,
            $RouterPage.MediaCardSimilarPage,
            $RouterPage.MyChannelPage,
            $RouterPage.MainPage,
          ]"
          :max="1"
        >
          <component :is="Component" />
        </KeepAlive>
      </RouterView>
    </div>

    <UIAlert />
  </div>
</template>

<script setup lang="ts">
import useLogger from '@package/logger/src/use-logger';
import { SmartTvVijuPlayer } from '@package/media-player/src/player';
import { type Profile } from '@package/sdk/src/api';
import { isDefined, isFunction, isUndefined } from '@package/sdk/src/core';
import { FeatureToggle } from '@package/unleash/src/feature-toggle/index';
import * as Sentry from '@sentry/vue';
import type { SessionGetters, SessionState } from '@SMART/index';
import {
  analyticService,
  authService,
  channelsService,
  contentCacheManager,
  environmentService,
  featuresService,
  onboardingService,
  requestService,
  RouterPage,
  routerService,
  storeToRefs,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useSessionVariables,
  useTvChannelsStore,
} from '@SMART/index';
import { ulid } from 'ulid';
import { v4 as uuidv4 } from 'uuid';
import { computed, onErrorCaptured, ref, useTemplateRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';

import UIAlert from './components/alert/UIAlert.vue';

const route = useRoute();

const contentStore = useContentStore();
const sessionStore = useSessionStore();
const tvChannelsStore = useTvChannelsStore();
const mainPageStore = useMainPageStore();

const logger = useLogger('App.vue');

const { currentOffer, _profile, _user } = storeToRefs<SessionState, SessionGetters, unknown>(sessionStore);

const isMenuHidden = ref(false);
const isProcessResetSessionData = ref<Promise<unknown> | null>(null);
const cacheKey = ref(uuidv4());

const routeQuery = ref('{}');
const routeParams = ref('{}');

const isReleaseMode = environmentService.getVariable<boolean>('isRelease');

const menuRef = useTemplateRef<{
  hasFocusedChild: boolean;
}>('appMenuRef');
const hasFocusedMenu = computed(() => Boolean(menuRef.value?.hasFocusedChild));

// Перед релизом на прод, будем скрывать для прода - оставлять для остальных
const isBuildBadgeShown = computed(() => {
  if (!isReleaseMode) {
    return true;
  }

  return route.path.includes('settings') || route.path.includes('auth');
});

const hiddenAppMenuRoutes = [
  RouterPage.MediaCardPage,
  RouterPage.RecoverCodePage,
  RouterPage.MediaCardPlayerPage,
  RouterPage.ChannelsPlayerPage,
  RouterPage.AuthPage,
  RouterPage.LoginPage,
  RouterPage.SignUpPage,
  RouterPage.SignInPage,
  RouterPage.ProfilesPage,
  RouterPage.Offers,
  RouterPage.OfferInfo,
  RouterPage.OffersSpecial,
  RouterPage.ParentalPage,
  RouterPage.ParentalCodePage,
  RouterPage.ParentalCodeRecoverPage,
  RouterPage.AppExitPage,
  RouterPage.RecoverPasswordPage,
];

const routesToCacheAlways = [
  RouterPage.ProfilesPage,
  RouterPage.Offers,
  RouterPage.OfferInfo,
  RouterPage.AppExitPage,
  RouterPage.AuthPage,
  RouterPage.ParentalPage,
  RouterPage.ParentalCodePage,
  RouterPage.ParentalCodeRecoverPage,
  RouterPage.RecoverPasswordPage,
  RouterPage.RecoverCodePage,
  RouterPage.MediaCardPage,
];

const pathsToCache = [
  {
    to: RouterPage.CatalogPage,
    from: RouterPage.MediaCardPage,
    shouldCache: (to?: RouterPage, from?: RouterPage) => {
      if (to === RouterPage.CatalogPage && Object.keys(route.query).length > 0) {
        return false;
      }

      return true;
    },
  },
  { to: RouterPage.SearchPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.CollectionPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.MainPage, from: RouterPage.MediaCardPage },
  { to: RouterPage.MediaCardPlayerPage, from: RouterPage.MediaCardPlayerPage },
];

const routesWithNoCheckParams = [RouterPage.MediaCardPlayerPage];

const resetSessionData = () => {
  contentStore.reset();
  tvChannelsStore.reset();
  contentCacheManager.clear();
  mainPageStore.reset();
  requestService.clearCache();
  cacheKey.value = uuidv4();

  isProcessResetSessionData.value = Promise.all([
    channelsService.fetchChannels(),
    contentStore.fetchGenres(),
    contentStore.fetchPeriods(),
    contentStore.fetchCountries(),
    sessionStore.fetchOffers(),
    featuresService.fetchFeatureFlags(),
  ]);
};

routerService.addBeforeEach(async (_to, _from, next) => {
  if (isProcessResetSessionData.value) {
    await isProcessResetSessionData.value;
    isProcessResetSessionData.value = null;
  }

  next();
});

authService.emitter.on('user.logout', () => {
  onboardingService.resetParentalCode();
});

const onDidProfileUpdated = (oldProfile: Profile, newProfile: Profile) => {
  const routeName = route.name;

  analyticService.setFeatureFlags({
    [FeatureToggle.AllPlatformTestAA]: featuresService.getFeatureFlag(FeatureToggle.AllPlatformTestAA)?.variant?.value,
  });

  SmartTvVijuPlayer.setSession({ offer: currentOffer.value, user: _user?.value });

  if ((isUndefined(oldProfile) && isUndefined(newProfile)) || (!isUndefined(oldProfile) && isUndefined(newProfile))) {
    return;
  }

  /**
   * Сессия может появится при смене пароля, там мы НЕ сбрасываем все, чтобы страница не слетела
   */
  if (routeName === RouterPage.AuthPage) {
    return;
  }

  resetSessionData();
};

const onBeforeEach = (to?: RouterPage, from?: RouterPage) => {
  isMenuHidden.value = isDefined(to) && hiddenAppMenuRoutes.includes(to);

  if (to && routesToCacheAlways.includes(to)) {
    return;
  }

  const path = pathsToCache.find((path) => {
    if (path.to && path.from) {
      return (to === path.to && from === path.from) || (from === path.to && to === path.from);
    }

    return path.from ? path.from === from : path.to === to;
  });

  const shouldCache = Boolean(path && isFunction(path.shouldCache) ? path.shouldCache(to, from) : path);

  if (!shouldCache) {
    cacheKey.value = uuidv4();
  }
};

const menuKey = computed(() => _user?.value?.id ?? ulid(3));

watch(() => route.name as RouterPage, onBeforeEach, { immediate: true });

watch(
  () => route.params,
  (params) => {
    const paramsString = JSON.stringify(params);
    if (
      routerService.lastVisitedRoute?.name === route.name &&
      routeParams.value !== paramsString &&
      !routesWithNoCheckParams.includes(route.name as RouterPage)
    ) {
      routeParams.value = paramsString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(
  () => route.query,
  (query) => {
    const queryString = JSON.stringify(query);
    if (
      routerService.lastVisitedRoute?.name === route.name &&
      routeQuery.value !== queryString &&
      !routesWithNoCheckParams.includes(route.name as RouterPage)
    ) {
      routeQuery.value = queryString;
      cacheKey.value = uuidv4();
    }
  },
);
watch(() => _profile?.value, onDidProfileUpdated, { immediate: true });

// Скорей всего, если мы оказались тут - то у нас крашнулось приложение
onErrorCaptured((error) => {
  logger.error(error?.toString());
  logger.error(error?.stack as string);

  Sentry.captureException(error);

  throw error;
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px' as adjust;

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.pageMenuExpanded {
  left: adjust.adjustPx(406px);
}
</style>
