<template>
  <div :class="$style.episode">
    <div :class="$style.episodePreview">
      <app-image :src="episode.preview" :width="400" />
      <div v-if="!isActiveSubscription && !isPartnerSubscription" :class="$style.hint">
        {{ $t('media.freeContent.availableAfterSubscription') }}
      </div>
    </div>

    <h2 :class="$style.episodeTitle">
      <span>{{ $t('media.freeContent.episodeNumber', { number: episode.number }) }}</span>
    </h2>

    <p :class="$style.episodeDescription">
      {{ episode.description }}
    </p>

    <p :class="$style.episodeTimeLeft">
      {{ $t('media.freeContent.durationLeftInMinutes', { minutes: durationLeftInMinutes }) }}
    </p>
    <smart-tv-video-control-button
      v-if="!isActiveSubscription && !isPartnerSubscription"
      :class="$style.nextButton"
      :is-label-shown="true"
      aria-haspopup="true"
      :text="primaryButtonText"
      :on-click="requestNextEpisode"
      :focus-key="PlayerFocusKeys.PLAYER_NEXT_EPISODE_PLAY"
      @focus="$emit('active')"
      @blur="$emit('blur')"
    >
      <span>{{ primaryButtonText }}</span>
    </smart-tv-video-control-button>
  </div>
</template>

<script setup lang="ts">
import type { Episode, Season } from '@package/sdk/src/api';
import AppImage from '@PLAYER/player/components/ui/AppImage.vue';
import { getPlayButtonText } from '@PLAYER/player/helpers';
import useNextSerialEpisode from '@PLAYER/player/modules/content/use-next-serial-episode';
import useSession from '@PLAYER/player/modules/session/use-session';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import useSmartNavigatable from '@PLAYER/player/versions/smart/modules/smart-navigation/use-smarttv-navigatable';
import { computed } from 'vue';

interface Props {
  episode: Episode;
  season: Season;
  isEpisodeSelected: boolean;
  isEpisodeUnavailable: boolean;
  durationLeftInMinutes: number;
}

const props = defineProps<Props>();

const { PlayerFocusKeys } = useSmartNavigatable();

defineEmits(['active', 'selected', 'blur']);

const { isActiveSubscription, isPartnerSubscription, isAuth, subscription, hasOfferTrial, offer } = useSession();
const { requestNextAvailableEpisode } = useNextSerialEpisode();

const primaryButtonText = computed(() => {
  const episodeData = {
    season: props.season.number,
    episode: props.episode.number,
  };

  return getPlayButtonText({
    isAuth: isAuth.value,
    isActiveSubscription: isActiveSubscription.value,
    isPartnerSubscription: isPartnerSubscription.value,
    canContinueWatch: false,
    isVOD: true,
    isLive: false,
    isWatchButtonWithContentTypeText: true,
    offer: offer.value,
    hasTrialOffer: hasOfferTrial.value,
    subscription: subscription.value,
    episodeData,
  });
});

const requestNextEpisode = () => {
  requestNextAvailableEpisode();
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.episode {
  display: flex;
  flex-direction: column;
  padding: adjust.adjustPx(24px);
  width: adjust.adjustPx(500px);
  min-height: adjust.adjustPx(488px);
  border: none;
  border-radius: adjust.adjustPx(32px);
  background-color: var(--color-notheme-bg-secondary-80);
  text-align: left;

  & > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.episodePreview {
  position: relative;
  width: adjust.adjustPx(452px);
  height: adjust.adjustPx(230px);
  border-radius: adjust.adjustPx(16px);
  overflow: hidden;

  @include smartTvFonts.SmartTvLabel-1();

  img {
    width: adjust.adjustPx(452px);
    height: adjust.adjustPx(230px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjust.adjustPx(16px);
    background: var(--color-notheme-dim-black-40);
  }
}

.episodePreviewTimeline {
  position: absolute;
  bottom: 0;
  z-index: 99;
  height: adjust.adjustPx(6px);
  border-radius: adjust.adjustPx(4px);
  background-color: var(--color-notheme-bg-accent);
}

.hint {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  width: adjust.adjustPx(230px);
  color: var(--color-text-primary);
  text-wrap: wrap;
  text-align: center;

  @include smartTvFonts.SmartTvLabel-3();
}

.episodeTitle {
  margin-top: adjust.adjustPx(32px);
  width: adjust.adjustPx(452px);
  overflow: hidden;
  color: var(--color-notheme-text-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvLabel-1();
}

.episodeDescription {
  margin: adjust.adjustPx(8px) 0 adjust.adjustPx(16px) 0;

  width: adjust.adjustPx(452px);
  overflow: hidden;
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvBody-2();

  @supports (-webkit-line-clamp: 2) {
    display: -webkit-box;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.episodeTimeLeft {
  margin: 0 0 adjust.adjustPx(16px) 0;
  width: adjust.adjustPx(368px);
  overflow: hidden;
  color: var(--color-notheme-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;

  @include smartTvFonts.SmartTvLabel-3();

  @supports (-webkit-line-clamp: 2) {
    display: -webkit-box;
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.episodeStatus {
  margin-top: adjust.adjustPx(16px);
  overflow: hidden;
  color: var(--color-notheme-text-tertiary);
  white-space: nowrap;
  text-overflow: ellipsis;

  @include smartTvFonts.SmartTvBody-1();
}

.nextButton {
  display: flex;
  justify-content: center;
  width: 100%;
  background: var(--color-notheme-bg-tertiary-80);
}

.active {
  box-shadow: inset 0 0 0 adjust.adjustPx(7px) var(--color-bg-accent);
}

.selected {
  background-color: var(--color-notheme-bg-hover-80);
}

.disabled {
  color: var(--color-notheme-text-disabled);
}
</style>
