import useLogger from '@package/logger/src/use-logger';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import { translate } from '@PLAYER/player/modules/localization/translate';
import { onErrorCaptured, ref } from 'vue';

export default function useGlobalErrorHandler() {
  const logger = useLogger('use-global-error-handler', 'media-player');
  const safeEventBus = useSafeEventBus();
  const externalEventBus = useSafeExternalEventBus();

  const hasCriticalError = ref(false);
  const errorTitle = ref('');
  const errorText = ref('');
  const restorePlayerRestoreCountExceeded = ref(false);

  const MAX_RESTORE_PLAYER_MAX_COUNT = 2;
  let currentRestorePlayerCount = 0;

  safeEventBus.on('onSourceUpdated', () => {
    if (currentRestorePlayerCount >= MAX_RESTORE_PLAYER_MAX_COUNT) {
      restorePlayerRestoreCountExceeded.value = true;
    }

    currentRestorePlayerCount += 1;
    hasCriticalError.value = false;
  });

  onErrorCaptured((err) => {
    logger.error(err);
    console.error(err);

    hasCriticalError.value = true;

    errorText.value = `${err.name}: ${err.message}`;
    errorTitle.value = translate('error.unexpectedError');

    externalEventBus.emit('error', err);

    return false;
  });

  return {
    restorePlayerRestoreCountExceeded,
    hasCriticalError,
    errorTitle,
    errorText,
  };
}
