<template>
  <section
    :class="{
      [$style.content]: true,
      [$style.contentActive]: isKeyboardHidden,
    }"
  >
    <SkeletonButtons v-show="isSkeletonShown" />

    <UITypography
      v-if="isInputEmpty"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.suggestTitle') }}
    </UITypography>
    <UITypography
      v-else-if="shouldShowNotFound"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.notFoundTitle') }}
    </UITypography>
    <UITypography
      v-else-if="shouldShowFound"
      :class="$style.title"
      shimmer-variant="subtitle"
      :is-loading="isFirstChunkLoading"
    >
      {{ $t('pages.search.foundTitle', { input }) }}
    </UITypography>

    <div :class="$style.posters">
      <UIContent
        scroll-block="start"
        :on-load-chunk="onLoadCollectionChunk"
        :content-type="CollectionContentType.Content"
        :items-per-row="itemsPerRow"
        :items-per-scroll="itemsPerScroll"
        :first-load-size="firstLoadSize"
        :force-update="shouldUpdateContent"
        :split-first-load="1"
        :is-chunk-loading="isLoading"
        @update:items="onResultsUpdate"
        @activated="onActivatedContent"
        @select:moment="onSelect"
        @active="onActiveContent"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { ItemPageFrom, useSearchPageAnalytics } from '@package/sdk/src/analytics';
import type { Media, MediaContentType, Moment } from '@package/sdk/src/api';
import { CollectionContentType } from '@package/sdk/src/api';
import { debounce } from '@package/sdk/src/core';
import {
  analyticService,
  catalogService,
  type History,
  searchService,
  useMediaContentActions,
} from '@package/smarttv-base/src';
import { SpatialNavigation } from '@package/smarttv-navigation/src/SpatialNavigation';
import { useSessionVariables } from '@SMART/index';
import { computed, onMounted, ref, watch } from 'vue';

import UIContent from '@/components/content/UIContent.vue';
import type { ContentRowItem } from '@/components/content/UiContentRow.vue';
import { useUiContent } from '@/components/content/useUiContent';

import UITypography from '../typography/UITypography.vue';

const { input, isSkeletonShown, history, isInputEmpty } = defineProps<{
  input: string;
  isSkeletonShown: boolean;
  isKeyboardHidden: boolean;
  isInputEmpty: boolean;
  history: History[];
}>();

const searchPageAnalytics = useSearchPageAnalytics(analyticService.sender);
const { isAuth } = useSessionVariables();
const { openContentPage } = useMediaContentActions();
const contentLoaded = ref(false);
const shouldUpdateContent = ref(false);

const shouldShowNotFound = computed(() => !isContentFound.value);
const shouldShowFound = computed(() => input && !isInputEmpty && isContentFound.value);

const resultsCount = ref(0);
const isContentFound = ref(true);

const emit = defineEmits<{
  (e: 'update:isSearchActive', val: boolean): void;
  (e: 'update:isInputEmpty', val: boolean): void;
  (e: 'history:fetch'): void;
  (e: 'keyboard:hide'): void;
}>();

const onActiveContent = () => {
  emit('keyboard:hide');
};

const onResultsUpdate = (items: ContentRowItem[]) => {
  if (input) {
    resultsCount.value = items.length;
  }
};

const onSelectPoster = async (item: Media | Moment, _: MediaContentType | CollectionContentType, index: number) => {
  if (isAuth.value && input && input?.length >= 3 && resultsCount.value) {
    const query = input;
    const preparedQuery = query.replace(' ', '');
    const historyQueries = history.map(({ query }) => query.replace(' ', ''));

    if (!historyQueries.filter((value) => value.includes(preparedQuery) || preparedQuery.includes(value)).length) {
      await searchService.setSearchHistory({ query: input, resultCount: resultsCount.value });
    }
  }

  return openContentPage({
    title: item.title,
    contentType: item.contentType,
    from: ItemPageFrom.Search,
    searchText: input,
    numberInResults: index,
    id: item.id,
  });
};

const isContentChanging = async () => {
  if (shouldUpdateContent.value) {
    contentLoaded.value = false;
  }

  return shouldUpdateContent.value;
};

const onActivatedContent = async () => {
  if (contentLoaded.value) {
    return;
  }

  contentLoaded.value = true;
};

const fetchItems = async (_?: unknown, params?: { page: number; size: number }) => {
  if (isInputEmpty || !isContentFound.value) {
    const items = await catalogService.fetchMovies({ ...params });

    return items || [];
  }

  const items = await searchService.searchByText({ text: input, limit: 100, ...params, perPage: params?.size });
  emit('update:isSearchActive', false);

  if (params?.page === 1) {
    isContentFound.value = Boolean(items.length);

    if (!isContentFound.value) {
      window.setTimeout(() => (shouldUpdateContent.value = true));
    }
  }

  searchPageAnalytics.onSearchResultsDisplayed(input, items, true);

  return items || [];
};

const onReloadContent = async () => {
  try {
    shouldUpdateContent.value = true;

    if (isAuth.value) {
      emit('history:fetch');
    }
  } finally {
    emit('update:isSearchActive', false);
    emit('update:isInputEmpty', true);
  }
};

const search = debounce(() => {
  shouldUpdateContent.value = true;
}, ConstantsConfigInstanceSmartTV.getProperty('debounceTimeoutModernMs'));

watch(
  () => input,
  () => {
    if (!input.length) {
      return onReloadContent();
    }

    search();
  },
);

const lastNavigationFocusKey = ref<string>();

const restoreFocusKey = (fallback: string) => {
  const target = lastNavigationFocusKey.value || '';

  SpatialNavigation.doesFocusableExist(target)
    ? SpatialNavigation.setFocus(target)
    : SpatialNavigation.setFocus(fallback);
};

const onSelect = (content: Media | Moment, index: number) => {
  lastNavigationFocusKey.value = SpatialNavigation.getCurrentFocusKey();
  onSelectItem(content, index);
};

const {
  firstLoadSize,
  itemsPerRow,
  itemsPerScroll,
  onLoadCollectionChunk,
  onSelectItem,
  isLoading,
  isFirstChunkLoading,
} = useUiContent({
  onSelect: onSelectPoster,
  isContentChanging,
  onLoaded: () => {
    shouldUpdateContent.value = false;
  },
  fetchItems,
  initialLoadSize: 20,
  itemsNumber: {
    perRow: 5,
    perScroll: 4,
  },
});

defineExpose({
  restoreFocusKey,
});

onMounted(() => {
  onReloadContent();
});
</script>

<style module lang="scss">
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;

@import '@/styles/colors';

.content {
  position: absolute;
  top: adjust.adjustPx(680px);
  left: adjust.adjustPx(140px);
  right: 0;
  margin-left: adjust.adjustPx(45px);
  height: 100%;

  &Active {
    top: adjust.adjustPx(80px);
    left: adjust.adjustPx(140px);
    right: 0;
  }
}

.posters {
  display: flex;
  margin-top: adjust.adjustPx(36px);
  margin-left: adjust.adjustPx(3px);
  height: calc(100% - adjust.adjustPx(80px));
  overflow-x: scroll;

  :global(.row) {
    margin-bottom: adjust.adjustPx(24px);
  }

  :global(.ceil) {
    margin-right: adjust.adjustPx(24px);
  }
}
</style>
