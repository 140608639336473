<template>
  <app-button
    :text="primaryButtonText"
    variation="button-primary"
    size="medium"
    @click="getContentAccessKindRedirect(episode?.accessKind, { redirectFromFreeEpisode: true })"
  />
</template>

<script setup lang="ts">
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import type { Episode, OfferV2 } from '@package/sdk/src/api';
import AppButton from '@PLAYER/player/components/ui/AppButton.vue';
import useContentAccess from '@PLAYER/player/modules/content/use-content-access';
import { translateContentButtonText } from '@PLAYER/player/modules/localization/translate';
import useSession from '@PLAYER/player/modules/session/use-session';
import { computed } from 'vue';

defineProps<{
  episode?: Episode;
}>();

const { getFreeEpisodePopupButtonText } = useContentMediaButtonTexts();
const { getContentAccessKindRedirect } = useContentAccess();

const { offer, promoOffer } = useSession();

const primaryButtonText = computed(() => {
  return translateContentButtonText(
    getFreeEpisodePopupButtonText({
      offer: offer.value as OfferV2,
      promoOffer: promoOffer.value,
    }),
  );
});
</script>
