import { isString } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';

export function useImage() {
  const loadImage = (src: string | undefined, onLoad: VoidFunction, onError: VoidFunction) => {
    if (!isClient || !isString(src)) {
      return;
    }

    const img = new Image();

    img.onload = onLoad;
    img.onerror = onError;
    img.src = src;

    return img;
  };

  return {
    loadImage,
  };
}
