<template></template>

<script lang="ts" setup>
import type MediaSourceTech from '@package/media-player-tech/src/media-source-tech';
import { isFunction } from '@package/sdk/src/core';
import type { AnyFunction } from '@PLAYER/player/base/function';
import usePlaybackAnalytics from '@PLAYER/player/modules/analytics/use-skip-playback-analytics';
import useStreamingAnalytics from '@PLAYER/player/modules/analytics/use-streaming-analytics';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import { VideoPlayerInternalEvent } from '@PLAYER/player/modules/event/internal-event';
import useSafeEventBus from '@PLAYER/player/modules/event/use-safe-event-bus';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import defineGlobalVueProperty from '@PLAYER/player/modules/global/define-global-vue-property';
import getGlobalVueProperty from '@PLAYER/player/modules/global/get-global-vue-property';
import { type UseDetectMediaTechSupportResult } from '@PLAYER/player/modules/hooks/use-native-playback-support';
import useSafeGetMediaSourceTech from '@PLAYER/player/modules/hooks/use-safe-get-media-source-tech';
import {
  PlayerGlobalProperty,
  type PlayerInstancePauseCommandOptions,
  type PlayerInstancePlayCommandOptions,
  type PlayerInstanceSeekCommandOptions,
} from '@PLAYER/player/modules/instance/interfaces';
import useManifestStore from '@PLAYER/player/modules/store/manifest-store';
import useVideoUIStore from '@PLAYER/player/modules/store/video-ui-store';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import useVideoInteractions from '@PLAYER/player/modules/video/use-video-interactions';
import useVideoPlayerVariables from '@PLAYER/player/modules/video/use-video-player-variables';
import { storeToRefs } from 'pinia';
import { getCurrentInstance, nextTick, onBeforeUnmount, onMounted, toRefs } from 'vue';

const props = defineProps<{
  injectGlobalFunctions: boolean;
  techPlaybackType: UseDetectMediaTechSupportResult;
  mediaSourceTech: MediaSourceTech;
}>();

const { techPlaybackType } = toRefs(props);

const eventBus = useSafeEventBus();
const externalEventBus = useSafeExternalEventBus();
const videoInteractions = useVideoInteractions();
const videoUIStore = useVideoUIStore();

const { sendAutoStreamingEvent } = useStreamingAnalytics();
const playbackAnalytics = usePlaybackAnalytics();

const playbackActions = usePlaybackActions();
const manifestStore = useManifestStore();

const { manifestUrl } = storeToRefs(manifestStore);
const { isFullScreenEnabled, isPictureInPictureEnabled, isPlaying } = storeToRefs(videoUIStore);
const { normalizedDisplayedCurrentTime } = useVideoPlayerVariables();

const getMediaSourceTech = useSafeGetMediaSourceTech();

const seekTo = (options: PlayerInstanceSeekCommandOptions) => {
  const { offset, coldStart, manual } = options;

  playbackActions.seekTo({ offset, coldStart, manual });
};

const startLoad = (startPosition = 0) => {
  const mediaSourceTech = getMediaSourceTech.value;

  videoUIStore.setIsAutoStartLoad(true);

  mediaSourceTech.startLoad(startPosition);
};

const stopLoad = () => {
  const mediaSourceTech = getMediaSourceTech.value;

  videoUIStore.setIsAutoStartLoad(false);

  mediaSourceTech.stopLoad();
};

const playSource = (options: PlayerInstancePlayCommandOptions = { manual: false }) => {
  playbackActions.doPlay({ manual: options.manual, type: 'click' });
};

const pauseSource = (options: PlayerInstancePauseCommandOptions = { manual: false }) => {
  playbackActions.doPause({ manual: options.manual, type: 'click' });
};

const openPictureInPicture = () => videoInteractions.openPictureInPicture();

const closePictureInPicture = () => videoInteractions.closePictureInPicture();

const requestFullscreen = () => videoInteractions.requestFullscreen();

const exitFullscreen = () => videoInteractions.exitFullscreen();

const getSourceLink = () => manifestUrl.value;

const getFullscreenState = () => isFullScreenEnabled.value;

const getPictureInPictureState = () => isPictureInPictureEnabled.value;
const startMediaSession = () => {
  playbackAnalytics.setStartTimeStreaming(normalizedDisplayedCurrentTime.value);
};

const endMediaSession = () => {
  if (!isPlaying.value) {
    return;
  }

  sendAutoStreamingEvent();
};

/**
 * В Safari это работает хреново, плеер периодичеси зависает. Сходу проблема непонятна, оставлю ее решение потомкам
 *
 * @type {boolean}
 */
const isOfflineStorageEnabled = false;

const requestSaveMediaOffline = (url: string) => {
  if (isOfflineStorageEnabled) {
    const mediaSourceTech = getMediaSourceTech.value;
    mediaSourceTech.requestSaveMediaOffline(url);
  }
};

/**
 * @description Добавляем возможность управлять плеером "извне"
 * @see {VijuPlayer}
 */
if (props.injectGlobalFunctions) {
  const app = getCurrentInstance();

  const isCreatedFunc = getGlobalVueProperty(app, PlayerGlobalProperty.MediaTechCreated);

  const isCreated = isFunction(isCreatedFunc) && isCreatedFunc() === true;

  if (!isCreated) {
    interface GlobalPropertyAction {
      property: PlayerGlobalProperty;
      func: AnyFunction;
    }

    const actions: GlobalPropertyAction[] = [
      { property: PlayerGlobalProperty.Play, func: playSource },
      { property: PlayerGlobalProperty.Pause, func: pauseSource },
      { property: PlayerGlobalProperty.SeekTo, func: seekTo },
      { property: PlayerGlobalProperty.StopLoad, func: stopLoad },
      { property: PlayerGlobalProperty.StartLoad, func: startLoad },

      { property: PlayerGlobalProperty.GetFullscreenState, func: getFullscreenState },
      { property: PlayerGlobalProperty.ExitFullscreen, func: exitFullscreen },
      { property: PlayerGlobalProperty.RequestFullscreen, func: requestFullscreen },

      { property: PlayerGlobalProperty.GetSourceLink, func: getSourceLink },

      { property: PlayerGlobalProperty.GetPictureInPictureState, func: getPictureInPictureState },
      { property: PlayerGlobalProperty.OpenPictureInPicture, func: openPictureInPicture },
      { property: PlayerGlobalProperty.ClosePictureInPicture, func: closePictureInPicture },
      { property: PlayerGlobalProperty.MediaTechCreated, func: () => true },

      { property: PlayerGlobalProperty.StartMediaSession, func: startMediaSession },
      { property: PlayerGlobalProperty.EndMediaSession, func: endMediaSession },
      { property: PlayerGlobalProperty.RequestSaveMediaOffline, func: requestSaveMediaOffline },
    ];

    for (const { property, func } of actions) {
      defineGlobalVueProperty(app, property, func);
    }

    nextTick(() => externalEventBus.emit('manifest-initialized', new VideoPlayerExternalEvent(undefined)));
  }
}

const DEBUG_INFO_TICK_TIME = 2000;
const TICK_CHECKING_BUFFER_LENGTH = 1000;

let timeoutDebugId: number;
let bufferTimeoutId: number;

const tickBufferLength = () => {
  const mediaSourceTech = getMediaSourceTech.value;

  const { buffer } = mediaSourceTech;

  bufferTimeoutId = window.setTimeout(() => {
    const { length, start } = buffer;

    videoUIStore.setBufferInfo({ length, start });

    tickBufferLength();
  }, TICK_CHECKING_BUFFER_LENGTH);
};

const tickDebugInfo = () => {
  timeoutDebugId = window.setTimeout(() => {
    const { videoCodec, audioCodec, currentQualityLevelHeight, latency, bandwidth, buffer } = props.mediaSourceTech;

    eventBus.emit(
      'onDebugInfoUpdated',
      new VideoPlayerInternalEvent({
        bandwidth,
        bufferLength: buffer.length,
        videoCodec,
        audioCodec,
        realQualityLevelHeight: currentQualityLevelHeight,
        latency,
      }),
    );

    tickDebugInfo();
  }, DEBUG_INFO_TICK_TIME);
};

onMounted(() => {
  tickBufferLength();

  tickDebugInfo();
});

onBeforeUnmount(() => {
  if (bufferTimeoutId) {
    window.clearTimeout(bufferTimeoutId);
  }

  if (timeoutDebugId) {
    window.clearTimeout(timeoutDebugId);
  }
});
</script>
