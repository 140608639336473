import { ref } from 'vue';

export function useFpsAnimation(duration: number, fps: number) {
  const startTime = ref(0);
  const animationFrameId = ref(0);
  const frameInterval = 1000 / fps;
  const lastFrameTime = ref(0);

  const progress = ref(0);

  function step(timestamp: number) {
    if (!startTime.value) {
      startTime.value = timestamp;
    }
    const elapsed = timestamp - startTime.value;

    if (elapsed >= duration) {
      progress.value = 100;
      return;
    }

    if (timestamp - lastFrameTime.value >= frameInterval) {
      progress.value = (elapsed / duration) * 100;
      lastFrameTime.value = timestamp;
    }

    animationFrameId.value = requestAnimationFrame(step);
  }

  function start() {
    reset();
    animationFrameId.value = requestAnimationFrame(step);
  }

  function stop() {
    reset();
  }

  function reset() {
    progress.value = 0;
    startTime.value = 0;
    cancelAnimationFrame(animationFrameId.value);
  }

  return { start, stop, progress };
}
