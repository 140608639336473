<template>
  <div data-smart-tv-control>
    <smart-tv-video-control-button text="" :on-click="onClick" :class="$style.button">
      <template #default="{ focused }">
        <div :class="$style.buttonSlot">
          <p :class="$style.title">{{ skipToMomentText }}</p>

          <p v-if="isLinkedKinomTargetContent" :class="{ [$style.subtitle]: true, [$style.focused]: focused }">
            {{ startOffsetTime }}
          </p>
          <p v-else :class="{ [$style.subtitle]: true, [$style.focused]: focused }">
            {{ episodeText }}
          </p>
        </div>
      </template>
    </smart-tv-video-control-button>
  </div>
</template>

<script lang="ts" setup>
import { MediaContentType, type Moment } from '@package/sdk/src/api';
import useContentInformation from '@PLAYER/player/modules/content/use-content-information';
import useContentLinkedKinom from '@PLAYER/player/modules/content/use-content-linked-kinom';
import { AppDateManager } from '@PLAYER/player/modules/date/date-manager';
import { VideoPlayerExternalEvent } from '@PLAYER/player/modules/event/external-event';
import useSafeExternalEventBus from '@PLAYER/player/modules/event/use-safe-external-event-bus';
import { t } from '@PLAYER/player/modules/localization/translate';
import useLayoutStore from '@PLAYER/player/modules/store/layout-store';
import usePlaybackActions from '@PLAYER/player/modules/video/use-playback-actions';
import SmartTvVideoControlButton from '@PLAYER/player/versions/smart/components/controls/SmartTvVideoControlButton.vue';
import { computed, onUnmounted } from 'vue';

const props = defineProps<{
  kinom: Moment;
}>();

const layoutStore = useLayoutStore();
const { isLinkedKinomTargetContent, clearLinkedKinom } = useContentLinkedKinom();

const playbackActions = usePlaybackActions();
const { content } = useContentInformation();
const externalEventBus = useSafeExternalEventBus();

const skipToMomentText = t('media.kinom.skipToMoment');
const episodeText = t('episodes.media.episodeNumber', {
  season: props.kinom.seasonNumber || 0,
  episode: props.kinom.episodeNumber || 0,
});

const rawStartTime = computed(() => props.kinom.startOffset);
const startOffsetTime = computed(() => AppDateManager.formatDuration(rawStartTime.value));

const onClick = () => {
  layoutStore.setIsLinkedKinomShown(false);

  if (!isLinkedKinomTargetContent.value) {
    // @ts-expect-error
    const episodeId = content.value.seasons[props.kinom.seasonNumber - 1]?.episodes[props.kinom.episodeNumber - 1]?.id;
    if (!episodeId) {
      return;
    }

    const event = {
      id: episodeId,
      type: MediaContentType.EPISODE,
      serial: {
        season: props.kinom.seasonNumber || 0,
        episode: props.kinom.episodeNumber || 0,
      },
      startOffset: props.kinom.startOffset,
    };

    externalEventBus.emit('media-play-requested', new VideoPlayerExternalEvent(event));

    return;
  }

  playbackActions.seekTo({ offset: rawStartTime.value, coldStart: false });
};

onUnmounted(() => {
  clearLinkedKinom();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/adjust-smart-px.scss' as adjust;
@use '@package/ui/src/styles/smarttv-fonts' as smartTvFonts;

.button {
  margin-left: adjust.adjustPx(24px);
  padding: 0 adjust.adjustPx(24px);
}

.buttonSlot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include smartTvFonts.SmartTvLabel-2();
}

.subtitle {
  @include smartTvFonts.SmartTvBody-3();

  color: var(--color-notheme-text-secondary);
}

.focused {
  color: unset;
}
</style>
