import type { DsmlRequestBodyV2Params } from '../code/dsml-v2-request-transformer';
import getActualToken from '../code/get-actual-token';
import { AbstractSendEventRequest } from './abstract-send-event-request';
import { DSML_ENDPOINTS, getBaseUrl } from './endpoint';

class SendEventsBatchRequest extends AbstractSendEventRequest {
  public constructor(protected override readonly payload: unknown) {
    super(payload);
  }

  protected override get url() {
    return `${getBaseUrl()}/${DSML_ENDPOINTS.EVENTS_BATCH}`;
  }
}

export async function sendEventsBatch(events: DsmlRequestBodyV2Params[]): Promise<void> {
  const token = await getActualToken();

  const request = new SendEventsBatchRequest(events);
  request.setToken(token);

  await request.send();
}
