<template>
  <ul :class="$style.stamps">
    <li v-for="timestamp in timestampsArray" :key="timestamp" :class="$style.stamp">{{ timestamp }}</li>
  </ul>
</template>

<script setup lang="ts">
import { toPixel } from '@package/sdk/src/core';
import { getTimeInSeconds } from '@PLAYER/player/base/date';
import { computed } from 'vue';

interface Props {
  duration: number;
  timelineWidth: number;
}

const { duration, timelineWidth } = defineProps<Props>();

const streamDurationInHours = computed(() => duration / 3600);
const streamDurationInMinutes = computed(() => duration / 60);

const getTimestampsArray = () => {
  const currentTimeInSeconds = getTimeInSeconds(new Date());
  // Текущий час
  const currentHour = Math.trunc(currentTimeInSeconds / 3600);

  const timestampsArray = [];

  const normalizedStreamDurationInHours = Math.round(streamDurationInHours.value);

  for (let i = normalizedStreamDurationInHours; i >= 0; i--) {
    // Результат будет всегда в диапазоне от 0 до 23
    const normalizedTimestampHour = String((currentHour - i + 24) % 24);

    const normalizedTimestamp = `${normalizedTimestampHour.padStart(2, '0')}:00`;

    timestampsArray.push(normalizedTimestamp);
  }

  return timestampsArray;
};

const getTimestampsOffsetInPx = () => {
  const currentTimeInSeconds = getTimeInSeconds(new Date());
  // Количество минут, прошедших с последнего часа
  const minutesSinceLastHour = (currentTimeInSeconds % 3600) / 60;

  const offset = (timelineWidth * minutesSinceLastHour) / streamDurationInMinutes.value;

  return toPixel(Math.trunc(offset));
};

const timestampsArray = computed(() => getTimestampsArray());
const timestampsOffsetInPx = computed(() => getTimestampsOffsetInPx());
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as webFonts;

.stamps {
  position: relative;
  right: v-bind(timestampsOffsetInPx);
  display: flex;
  justify-content: space-between;
  margin: 0 -14px;
  padding-bottom: var(--g-spacing-8);
}

.stamp {
  @include webFonts.WebCaption-1();

  &:after {
    position: relative;
    left: 50%;
    display: block;
    width: 1px;
    height: 4px;
    border-radius: 1px;
    background: var(--color-notheme-icon-tertiary);
    content: '';
  }
}
</style>
