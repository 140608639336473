<template>
  <div
    :class="{
      [$style.progress]: true,
      [$style.progressKinom]: isKinom,
    }"
    :style="{ width: `calc(${$toPercent(currentHandlePosition * 100)})` }"
  />
</template>

<script lang="ts" setup>
import useProjector from '@PLAYER/player/modules/hooks/use-projector';

defineProps<{
  currentHandlePosition: number;
}>();

const { isKinom } = useProjector();
</script>

<style lang="scss" module>
.progress {
  position: absolute;
  z-index: 2;
  width: 0;
  background-color: var(--color-notheme-bg-accent);
}

.progressKinom {
  height: 5px;
}
</style>
